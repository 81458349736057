import { Routes } from '@angular/router';

export const appRoutes: Routes = [
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin/admin.routes').then((r) => r.AdminRoutes),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/client/client.routes').then((r) => r.ClientRoutes),
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '',
  },
];
