/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateTicketRequest } from '../models/create-ticket-request';
import { EventLiveStreamResponse } from '../models/event-live-stream-response';
import { EventTicketsResponse } from '../models/event-tickets-response';
import { GetEventTicketsResponseSuccess } from '../models/get-event-tickets-response-success';
import { GetFanTicketsResponseSuccess } from '../models/get-fan-tickets-response-success';
import { GetOrganizerPaymentTicketsStatResponseSuccess } from '../models/get-organizer-payment-tickets-stat-response-success';
import { GiveTicketRequest } from '../models/give-ticket-request';
import { ResponseSuccess } from '../models/response-success';
import { ScanQrCodeRequest } from '../models/scan-qr-code-request';
import { ScanQrCodeResponseSuccess } from '../models/scan-qr-code-response-success';
import { UpdateTicketRequest } from '../models/update-ticket-request';

@Injectable({
  providedIn: 'root',
})
export class TicketService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getEventTickets
   */
  static readonly GetEventTicketsPath = '/events/{eventGUID}/tickets';

  /**
   * Information about event tickets.
   *
   * returns list of tickets
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEventTickets()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventTickets$Response(params: {
    /**
     * Event ID
     */
    eventGUID: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<StrictHttpResponse<GetEventTicketsResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TicketService.GetEventTicketsPath,
      'get',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetEventTicketsResponseSuccess>;
        }),
      );
  }

  /**
   * Information about event tickets.
   *
   * returns list of tickets
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEventTickets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventTickets(params: {
    /**
     * Event ID
     */
    eventGUID: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<GetEventTicketsResponseSuccess> {
    return this.getEventTickets$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetEventTicketsResponseSuccess>) =>
          r.body as GetEventTicketsResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getBoughtTickets
   */
  static readonly GetBoughtTicketsPath = '/events/{eventId}/bought_tickets';

  /**
   * Information about bought tickets.
   *
   * returns list of tickets
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBoughtTickets()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBoughtTickets$Response(params: {
    /**
     * Event ID
     */
    eventId: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<StrictHttpResponse<GetFanTicketsResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TicketService.GetBoughtTicketsPath,
      'get',
    );
    if (params) {
      rb.path('eventId', params.eventId, {});
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetFanTicketsResponseSuccess>;
        }),
      );
  }

  /**
   * Information about bought tickets.
   *
   * returns list of tickets
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBoughtTickets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBoughtTickets(params: {
    /**
     * Event ID
     */
    eventId: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<GetFanTicketsResponseSuccess> {
    return this.getBoughtTickets$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetFanTicketsResponseSuccess>) =>
          r.body as GetFanTicketsResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getOrganizerEventPaymentTickets
   */
  static readonly GetOrganizerEventPaymentTicketsPath =
    '/organizer/events/{eventGUID}/payment_tickets';

  /**
   * Information about event tickets stat for organizer.
   *
   * returns list of tickets with count of scanned tickets and total bough tickets
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizerEventPaymentTickets()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizerEventPaymentTickets$Response(params: {
    /**
     * event ID
     */
    eventGUID: string;
  }): Observable<
    StrictHttpResponse<GetOrganizerPaymentTicketsStatResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      TicketService.GetOrganizerEventPaymentTicketsPath,
      'get',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetOrganizerPaymentTicketsStatResponseSuccess>;
        }),
      );
  }

  /**
   * Information about event tickets stat for organizer.
   *
   * returns list of tickets with count of scanned tickets and total bough tickets
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrganizerEventPaymentTickets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizerEventPaymentTickets(params: {
    /**
     * event ID
     */
    eventGUID: string;
  }): Observable<GetOrganizerPaymentTicketsStatResponseSuccess> {
    return this.getOrganizerEventPaymentTickets$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<GetOrganizerPaymentTicketsStatResponseSuccess>,
        ) => r.body as GetOrganizerPaymentTicketsStatResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation scanTicket
   */
  static readonly ScanTicketPath =
    '/organizer/events/{eventGUID}/qrcode_scanner';

  /**
   * Information about event ticket for organizer by QR code.
   *
   * returns ticket by QR code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scanTicket()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  scanTicket$Response(params: {
    /**
     * event ID
     */
    eventGUID: string;

    /**
     * Post
     */
    body: ScanQrCodeRequest;
  }): Observable<StrictHttpResponse<ScanQrCodeResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TicketService.ScanTicketPath,
      'post',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ScanQrCodeResponseSuccess>;
        }),
      );
  }

  /**
   * Information about event ticket for organizer by QR code.
   *
   * returns ticket by QR code
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `scanTicket$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  scanTicket(params: {
    /**
     * event ID
     */
    eventGUID: string;

    /**
     * Post
     */
    body: ScanQrCodeRequest;
  }): Observable<ScanQrCodeResponseSuccess> {
    return this.scanTicket$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ScanQrCodeResponseSuccess>) =>
          r.body as ScanQrCodeResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getOrganizerEventTickets
   */
  static readonly GetOrganizerEventTicketsPath =
    '/organizer/events/{eventGUID}/tickets';

  /**
   * Information about event tickets.
   *
   * returns list of tickets
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizerEventTickets()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizerEventTickets$Response(params: {
    /**
     * event ID
     */
    eventGUID: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<StrictHttpResponse<EventTicketsResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TicketService.GetOrganizerEventTicketsPath,
      'get',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventTicketsResponse>;
        }),
      );
  }

  /**
   * Information about event tickets.
   *
   * returns list of tickets
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrganizerEventTickets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizerEventTickets(params: {
    /**
     * event ID
     */
    eventGUID: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<EventTicketsResponse> {
    return this.getOrganizerEventTickets$Response(params).pipe(
      map(
        (r: StrictHttpResponse<EventTicketsResponse>) =>
          r.body as EventTicketsResponse,
      ),
    );
  }

  /**
   * Path part for operation createTicket
   */
  static readonly CreateTicketPath = '/tickets';

  /**
   * Create ticket.
   *
   * create new ticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTicket()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTicket$Response(params: {
    /**
     * CreateTicketRequest
     */
    body: CreateTicketRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TicketService.CreateTicketPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Create ticket.
   *
   * create new ticket
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createTicket$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTicket(params: {
    /**
     * CreateTicketRequest
     */
    body: CreateTicketRequest;
  }): Observable<ResponseSuccess> {
    return this.createTicket$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation updateTicket
   */
  static readonly UpdateTicketPath = '/tickets/{ticketGUID}';

  /**
   * Update ticket.
   *
   * update ticket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTicket()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTicket$Response(params: {
    /**
     * Ticket ID
     */
    ticketGUID: string;

    /**
     * UpdateTicketRequest
     */
    body: UpdateTicketRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TicketService.UpdateTicketPath,
      'put',
    );
    if (params) {
      rb.path('ticketGUID', params.ticketGUID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Update ticket.
   *
   * update ticket
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateTicket$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTicket(params: {
    /**
     * Ticket ID
     */
    ticketGUID: string;

    /**
     * UpdateTicketRequest
     */
    body: UpdateTicketRequest;
  }): Observable<ResponseSuccess> {
    return this.updateTicket$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation ticketLivestream
   */
  static readonly TicketLivestreamPath = '/tickets/{ticketGUID}/live_stream';

  /**
   * Returns live stream for ticket event.
   *
   * Returns live stream for ticket event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ticketLivestream()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketLivestream$Response(params: {
    /**
     * Payment Ticket ID
     */
    ticketGUID: string;
  }): Observable<StrictHttpResponse<EventLiveStreamResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TicketService.TicketLivestreamPath,
      'get',
    );
    if (params) {
      rb.path('ticketGUID', params.ticketGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventLiveStreamResponse>;
        }),
      );
  }

  /**
   * Returns live stream for ticket event.
   *
   * Returns live stream for ticket event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `ticketLivestream$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ticketLivestream(params: {
    /**
     * Payment Ticket ID
     */
    ticketGUID: string;
  }): Observable<EventLiveStreamResponse> {
    return this.ticketLivestream$Response(params).pipe(
      map(
        (r: StrictHttpResponse<EventLiveStreamResponse>) =>
          r.body as EventLiveStreamResponse,
      ),
    );
  }

  /**
   * Path part for operation printTicket
   */
  static readonly PrintTicketPath = '/tickets/{ticketGUID}/print';

  /**
   * Prints ticket as pdf version.
   *
   * Prints ticket as pdf version
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `printTicket()` instead.
   *
   * This method doesn't expect any request body.
   */
  printTicket$Response(params: {
    /**
     * Payment Ticket ID
     */
    ticketGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TicketService.PrintTicketPath,
      'get',
    );
    if (params) {
      rb.path('ticketGUID', params.ticketGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Prints ticket as pdf version.
   *
   * Prints ticket as pdf version
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `printTicket$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  printTicket(params: {
    /**
     * Payment Ticket ID
     */
    ticketGUID: string;
  }): Observable<ResponseSuccess> {
    return this.printTicket$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation acceptPayment
   */
  static readonly AcceptPaymentPath =
    '/tickets/gift/{paymentTicketGUID}/accept';

  /**
   * accept ticket.
   *
   * fan accept ticket, will change ticket status to presented
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acceptPayment()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptPayment$Response(params: {
    /**
     * Payment Ticket ID
     */
    paymentTicketGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TicketService.AcceptPaymentPath,
      'post',
    );
    if (params) {
      rb.path('paymentTicketGUID', params.paymentTicketGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * accept ticket.
   *
   * fan accept ticket, will change ticket status to presented
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `acceptPayment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptPayment(params: {
    /**
     * Payment Ticket ID
     */
    paymentTicketGUID: string;
  }): Observable<ResponseSuccess> {
    return this.acceptPayment$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation giveTicket
   */
  static readonly GiveTicketPath = '/tickets/gift/{paymentTicketGUID}/give';

  /**
   * Give ticket to guest.
   *
   * give ticket to guest, will change ticket status to waiting_to_present
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `giveTicket()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  giveTicket$Response(params: {
    /**
     * Payment Ticket ID
     */
    paymentTicketGUID: string;

    /**
     * Get
     */
    body: GiveTicketRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TicketService.GiveTicketPath,
      'post',
    );
    if (params) {
      rb.path('paymentTicketGUID', params.paymentTicketGUID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Give ticket to guest.
   *
   * give ticket to guest, will change ticket status to waiting_to_present
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `giveTicket$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  giveTicket(params: {
    /**
     * Payment Ticket ID
     */
    paymentTicketGUID: string;

    /**
     * Get
     */
    body: GiveTicketRequest;
  }): Observable<ResponseSuccess> {
    return this.giveTicket$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation refusePayment
   */
  static readonly RefusePaymentPath =
    '/tickets/gift/{paymentTicketGUID}/refuse';

  /**
   * refuse ticker.
   *
   * fan refuse ticket, will change ticket status to own
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `refusePayment()` instead.
   *
   * This method doesn't expect any request body.
   */
  refusePayment$Response(params: {
    /**
     * Payment Ticket ID
     */
    paymentTicketGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      TicketService.RefusePaymentPath,
      'post',
    );
    if (params) {
      rb.path('paymentTicketGUID', params.paymentTicketGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * refuse ticker.
   *
   * fan refuse ticket, will change ticket status to own
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `refusePayment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  refusePayment(params: {
    /**
     * Payment Ticket ID
     */
    paymentTicketGUID: string;
  }): Observable<ResponseSuccess> {
    return this.refusePayment$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }
}
