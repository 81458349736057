import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AdminStoreModule } from '@app/store/root/admin';
import { ClientStoreModule } from '@app/store/root/client';
import { AppStoreModule } from '@app/store/root/app/app-store.module';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot({}, {}),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      name: 'Mouse Store',
      connectInZone: true,
    }),
    EffectsModule.forRoot([]),
    AppStoreModule,
    ClientStoreModule,
    AdminStoreModule,
  ],
  declarations: [],
})
export class RootStoreModule {}
