/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddMessageRequest } from '../models/add-message-request';
import { MessageArtistResponse } from '../models/message-artist-response';
import { MessageFanResponse } from '../models/message-fan-response';
import { MessageOrganizerResponse } from '../models/message-organizer-response';
import { MessageVenueResponse } from '../models/message-venue-response';
import { ResponseSuccess } from '../models/response-success';

@Injectable({
  providedIn: 'root',
})
export class MessagesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getArtistMessages
   */
  static readonly GetArtistMessagesPath = '/artist/messages';

  /**
   * Get artist messages.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArtistMessages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistMessages$Response(params?: {}): Observable<
    StrictHttpResponse<Array<MessageArtistResponse>>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      MessagesService.GetArtistMessagesPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<MessageArtistResponse>>;
        }),
      );
  }

  /**
   * Get artist messages.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getArtistMessages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistMessages(params?: {}): Observable<Array<MessageArtistResponse>> {
    return this.getArtistMessages$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<MessageArtistResponse>>) =>
          r.body as Array<MessageArtistResponse>,
      ),
    );
  }

  /**
   * Path part for operation getFanMessages
   */
  static readonly GetFanMessagesPath = '/fan/messages';

  /**
   * Get fan messages.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFanMessages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFanMessages$Response(params?: {}): Observable<
    StrictHttpResponse<Array<MessageFanResponse>>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      MessagesService.GetFanMessagesPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<MessageFanResponse>>;
        }),
      );
  }

  /**
   * Get fan messages.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFanMessages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFanMessages(params?: {}): Observable<Array<MessageFanResponse>> {
    return this.getFanMessages$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<MessageFanResponse>>) =>
          r.body as Array<MessageFanResponse>,
      ),
    );
  }

  /**
   * Path part for operation addMessage
   */
  static readonly AddMessagePath = '/messages';

  /**
   * Add message.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addMessage()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  addMessage$Response(params: {
    /**
     * AddMessageRequest
     */
    body: AddMessageRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      MessagesService.AddMessagePath,
      'post',
    );
    if (params) {
      rb.body(params.body, '*/*');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Add message.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addMessage$Response()` instead.
   *
   * This method sends `* / *` and handles request body of type `* / *`.
   */
  addMessage(params: {
    /**
     * AddMessageRequest
     */
    body: AddMessageRequest;
  }): Observable<ResponseSuccess> {
    return this.addMessage$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation removeMessage
   */
  static readonly RemoveMessagePath = '/messages/{messageGUID}';

  /**
   * Remove message by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeMessage$Response(params: {
    /**
     * Message ID
     */
    messageGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      MessagesService.RemoveMessagePath,
      'delete',
    );
    if (params) {
      rb.path('messageGUID', params.messageGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Remove message by id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeMessage(params: {
    /**
     * Message ID
     */
    messageGUID: string;
  }): Observable<ResponseSuccess> {
    return this.removeMessage$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getOrganizerMessages
   */
  static readonly GetOrganizerMessagesPath = '/organizer/messages';

  /**
   * Get organizer messages.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizerMessages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizerMessages$Response(params?: {}): Observable<
    StrictHttpResponse<Array<MessageOrganizerResponse>>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      MessagesService.GetOrganizerMessagesPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<MessageOrganizerResponse>>;
        }),
      );
  }

  /**
   * Get organizer messages.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrganizerMessages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrganizerMessages(params?: {}): Observable<
    Array<MessageOrganizerResponse>
  > {
    return this.getOrganizerMessages$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<MessageOrganizerResponse>>) =>
          r.body as Array<MessageOrganizerResponse>,
      ),
    );
  }

  /**
   * Path part for operation getVenueMessages
   */
  static readonly GetVenueMessagesPath = '/venue/messages';

  /**
   * Get venue messages.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVenueMessages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenueMessages$Response(params?: {}): Observable<
    StrictHttpResponse<Array<MessageVenueResponse>>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      MessagesService.GetVenueMessagesPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<MessageVenueResponse>>;
        }),
      );
  }

  /**
   * Get venue messages.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVenueMessages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenueMessages(params?: {}): Observable<Array<MessageVenueResponse>> {
    return this.getVenueMessages$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<MessageVenueResponse>>) =>
          r.body as Array<MessageVenueResponse>,
      ),
    );
  }
}
