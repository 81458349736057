/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddOrUpdateVenuesToEvent } from '../models/add-or-update-venues-to-event';
import { DeclineVenueRequestOfEvent } from '../models/decline-venue-request-of-event';
import { EventVenueResponse } from '../models/event-venue-response';
import { EventVenuesResponse } from '../models/event-venues-response';
import { RequestVenueOfEvent } from '../models/request-venue-of-event';
import { ResendVenueRequestOfEvent } from '../models/resend-venue-request-of-event';
import { ResponseSuccess } from '../models/response-success';
import { UpdateRequestVenueOfEvent } from '../models/update-request-venue-of-event';
import { VenuePrivateInfoResponse } from '../models/venue-private-info-response';

@Injectable({
  providedIn: 'root',
})
export class EventsVenueService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getVenuesOfEvent
   */
  static readonly GetVenuesOfEventPath = '/events/{eventGUID}/venues';

  /**
   * Get venues to event.
   *
   * Get venues to event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVenuesOfEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenuesOfEvent$Response(params: {
    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<StrictHttpResponse<EventVenuesResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsVenueService.GetVenuesOfEventPath,
      'get',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventVenuesResponse>;
        }),
      );
  }

  /**
   * Get venues to event.
   *
   * Get venues to event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVenuesOfEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenuesOfEvent(params: {
    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<EventVenuesResponse> {
    return this.getVenuesOfEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<EventVenuesResponse>) =>
          r.body as EventVenuesResponse,
      ),
    );
  }

  /**
   * Path part for operation addVenuesOfEvent
   */
  static readonly AddVenuesOfEventPath = '/events/{eventGUID}/venues';

  /**
   * Add venues to event.
   *
   * Add venues to event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addVenuesOfEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addVenuesOfEvent$Response(params: {
    /**
     * Event ID
     */
    eventGUID: string;

    /**
     * Struct
     */
    body: AddOrUpdateVenuesToEvent;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsVenueService.AddVenuesOfEventPath,
      'post',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Add venues to event.
   *
   * Add venues to event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addVenuesOfEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addVenuesOfEvent(params: {
    /**
     * Event ID
     */
    eventGUID: string;

    /**
     * Struct
     */
    body: AddOrUpdateVenuesToEvent;
  }): Observable<ResponseSuccess> {
    return this.addVenuesOfEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getVenuePrivateInfoOfEvent
   */
  static readonly GetVenuePrivateInfoOfEventPath =
    '/events/{eventGUID}/venues/{venueGUID}';

  /**
   * Get venue private info of event.
   *
   * Get venue private info of event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVenuePrivateInfoOfEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenuePrivateInfoOfEvent$Response(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Venue ID (Example: ID of venue profile)
     */
    venueGUID: string;
  }): Observable<StrictHttpResponse<VenuePrivateInfoResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsVenueService.GetVenuePrivateInfoOfEventPath,
      'get',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.path('venueGUID', params.venueGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VenuePrivateInfoResponse>;
        }),
      );
  }

  /**
   * Get venue private info of event.
   *
   * Get venue private info of event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVenuePrivateInfoOfEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenuePrivateInfoOfEvent(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Venue ID (Example: ID of venue profile)
     */
    venueGUID: string;
  }): Observable<VenuePrivateInfoResponse> {
    return this.getVenuePrivateInfoOfEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<VenuePrivateInfoResponse>) =>
          r.body as VenuePrivateInfoResponse,
      ),
    );
  }

  /**
   * Path part for operation removeVenueFromEvent
   */
  static readonly RemoveVenueFromEventPath =
    '/events/{eventGUID}/venues/{venueGUID}';

  /**
   * Remove venue from event.
   *
   * Remove venue from event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeVenueFromEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeVenueFromEvent$Response(params: {
    /**
     * eventGUID
     */
    eventGUID: string;

    /**
     * venueGUID
     */
    venueGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsVenueService.RemoveVenueFromEventPath,
      'delete',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.path('venueGUID', params.venueGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Remove venue from event.
   *
   * Remove venue from event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeVenueFromEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeVenueFromEvent(params: {
    /**
     * eventGUID
     */
    eventGUID: string;

    /**
     * venueGUID
     */
    venueGUID: string;
  }): Observable<ResponseSuccess> {
    return this.removeVenueFromEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation acceptVenueRequestOfEvent
   */
  static readonly AcceptVenueRequestOfEventPath =
    '/events/{eventGUID}/venues/{venueGUID}/accept';

  /**
   * Accept venue request to event.
   *
   * Accept venue request to event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acceptVenueRequestOfEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptVenueRequestOfEvent$Response(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Venue ID (Example: ID of venue profile)
     */
    venueGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsVenueService.AcceptVenueRequestOfEventPath,
      'put',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.path('venueGUID', params.venueGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Accept venue request to event.
   *
   * Accept venue request to event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `acceptVenueRequestOfEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptVenueRequestOfEvent(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Venue ID (Example: ID of venue profile)
     */
    venueGUID: string;
  }): Observable<ResponseSuccess> {
    return this.acceptVenueRequestOfEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation declineVenueRequestOfEvent
   */
  static readonly DeclineVenueRequestOfEventPath =
    '/events/{eventGUID}/venues/{venueGUID}/decline';

  /**
   * Decline venue request to event.
   *
   * Decline venue request to event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `declineVenueRequestOfEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  declineVenueRequestOfEvent$Response(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Venue ID (Example: ID of venue profile)
     */
    venueGUID: string;

    /**
     * Struct
     */
    body: DeclineVenueRequestOfEvent;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsVenueService.DeclineVenueRequestOfEventPath,
      'put',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.path('venueGUID', params.venueGUID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Decline venue request to event.
   *
   * Decline venue request to event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `declineVenueRequestOfEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  declineVenueRequestOfEvent(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Venue ID (Example: ID of venue profile)
     */
    venueGUID: string;

    /**
     * Struct
     */
    body: DeclineVenueRequestOfEvent;
  }): Observable<ResponseSuccess> {
    return this.declineVenueRequestOfEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getVenuesRequestOfEvent
   */
  static readonly GetVenuesRequestOfEventPath =
    '/events/{eventGUID}/venues/{venueGUID}/request';

  /**
   * Get venues request to event.
   *
   * Get Venues request to event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVenuesRequestOfEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenuesRequestOfEvent$Response(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Venue ID (Example: ID of venue profile)
     */
    venueGUID: string;
  }): Observable<StrictHttpResponse<EventVenueResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsVenueService.GetVenuesRequestOfEventPath,
      'get',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.path('venueGUID', params.venueGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventVenueResponse>;
        }),
      );
  }

  /**
   * Get venues request to event.
   *
   * Get Venues request to event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVenuesRequestOfEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenuesRequestOfEvent(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Venue ID (Example: ID of venue profile)
     */
    venueGUID: string;
  }): Observable<EventVenueResponse> {
    return this.getVenuesRequestOfEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<EventVenueResponse>) =>
          r.body as EventVenueResponse,
      ),
    );
  }

  /**
   * Path part for operation confirmUpdatesFromVenueToRequestOfEvent
   */
  static readonly ConfirmUpdatesFromVenueToRequestOfEventPath =
    '/events/{eventGUID}/venues/{venueGUID}/request';

  /**
   * Confirm and update request from venue of event.
   *
   * Confirm and update request from venue of event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirmUpdatesFromVenueToRequestOfEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmUpdatesFromVenueToRequestOfEvent$Response(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Venue ID (Example: ID of Venue profile)
     */
    venueGUID: string;

    /**
     * In
     */
    body: UpdateRequestVenueOfEvent;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsVenueService.ConfirmUpdatesFromVenueToRequestOfEventPath,
      'put',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.path('venueGUID', params.venueGUID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Confirm and update request from venue of event.
   *
   * Confirm and update request from venue of event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `confirmUpdatesFromVenueToRequestOfEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmUpdatesFromVenueToRequestOfEvent(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Venue ID (Example: ID of Venue profile)
     */
    venueGUID: string;

    /**
     * In
     */
    body: UpdateRequestVenueOfEvent;
  }): Observable<ResponseSuccess> {
    return this.confirmUpdatesFromVenueToRequestOfEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation sendRequestToVenueOfEvent
   */
  static readonly SendRequestToVenueOfEventPath =
    '/events/{eventGUID}/venues/{venueGUID}/request';

  /**
   * Send request to venue of event.
   *
   * Send request to venue of event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendRequestToVenueOfEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendRequestToVenueOfEvent$Response(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Venue ID (Example: ID of venue profile)
     */
    venueGUID: string;

    /**
     * In
     */
    body: RequestVenueOfEvent;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsVenueService.SendRequestToVenueOfEventPath,
      'post',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.path('venueGUID', params.venueGUID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Send request to venue of event.
   *
   * Send request to venue of event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendRequestToVenueOfEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendRequestToVenueOfEvent(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Venue ID (Example: ID of venue profile)
     */
    venueGUID: string;

    /**
     * In
     */
    body: RequestVenueOfEvent;
  }): Observable<ResponseSuccess> {
    return this.sendRequestToVenueOfEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation resendRequestToVenueOfEvent
   */
  static readonly ResendRequestToVenueOfEventPath =
    '/events/{eventGUID}/venues/{venueGUID}/resend';

  /**
   * Resend request to venue of event.
   *
   * Resend request to venue of event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resendRequestToVenueOfEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resendRequestToVenueOfEvent$Response(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Venue ID (Example: ID of venue profile)
     */
    venueGUID: string;

    /**
     * In
     */
    body: ResendVenueRequestOfEvent;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsVenueService.ResendRequestToVenueOfEventPath,
      'post',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.path('venueGUID', params.venueGUID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Resend request to venue of event.
   *
   * Resend request to venue of event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resendRequestToVenueOfEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resendRequestToVenueOfEvent(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Venue ID (Example: ID of venue profile)
     */
    venueGUID: string;

    /**
     * In
     */
    body: ResendVenueRequestOfEvent;
  }): Observable<ResponseSuccess> {
    return this.resendRequestToVenueOfEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }
}
