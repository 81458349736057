/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EventLiveStreamResponse } from '../models/event-live-stream-response';
import { LiveStreamStatResponse } from '../models/live-stream-stat-response';
import { ResponseSuccess } from '../models/response-success';

@Injectable({
  providedIn: 'root',
})
export class EventsLivestreamService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getLiveStreamDetails
   */
  static readonly GetLiveStreamDetailsPath = '/events/{eventGUID}/live_stream';

  /**
   * Get live stream details of event.
   *
   * Get live stream details of event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLiveStreamDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLiveStreamDetails$Response(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;
  }): Observable<StrictHttpResponse<EventLiveStreamResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsLivestreamService.GetLiveStreamDetailsPath,
      'get',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventLiveStreamResponse>;
        }),
      );
  }

  /**
   * Get live stream details of event.
   *
   * Get live stream details of event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLiveStreamDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLiveStreamDetails(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;
  }): Observable<EventLiveStreamResponse> {
    return this.getLiveStreamDetails$Response(params).pipe(
      map(
        (r: StrictHttpResponse<EventLiveStreamResponse>) =>
          r.body as EventLiveStreamResponse,
      ),
    );
  }

  /**
   * Path part for operation restartLiveStream
   */
  static readonly RestartLiveStreamPath =
    '/events/{eventGUID}/live_stream/restart';

  /**
   * Restart live stream of event.
   *
   * Restart live stream of event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restartLiveStream()` instead.
   *
   * This method doesn't expect any request body.
   */
  restartLiveStream$Response(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsLivestreamService.RestartLiveStreamPath,
      'put',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Restart live stream of event.
   *
   * Restart live stream of event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `restartLiveStream$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  restartLiveStream(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;
  }): Observable<ResponseSuccess> {
    return this.restartLiveStream$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation startLiveStream
   */
  static readonly StartLiveStreamPath = '/events/{eventGUID}/live_stream/start';

  /**
   * Start live stream of event.
   *
   * Start live stream of event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startLiveStream()` instead.
   *
   * This method doesn't expect any request body.
   */
  startLiveStream$Response(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsLivestreamService.StartLiveStreamPath,
      'put',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Start live stream of event.
   *
   * Start live stream of event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `startLiveStream$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  startLiveStream(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;
  }): Observable<ResponseSuccess> {
    return this.startLiveStream$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getLiveStreamStats
   */
  static readonly GetLiveStreamStatsPath =
    '/events/{eventGUID}/live_stream/stat';

  /**
   * Get live stream stats of event.
   *
   * Get live stream stats of event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLiveStreamStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLiveStreamStats$Response(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;
  }): Observable<StrictHttpResponse<LiveStreamStatResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsLivestreamService.GetLiveStreamStatsPath,
      'get',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<LiveStreamStatResponse>;
        }),
      );
  }

  /**
   * Get live stream stats of event.
   *
   * Get live stream stats of event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLiveStreamStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLiveStreamStats(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;
  }): Observable<LiveStreamStatResponse> {
    return this.getLiveStreamStats$Response(params).pipe(
      map(
        (r: StrictHttpResponse<LiveStreamStatResponse>) =>
          r.body as LiveStreamStatResponse,
      ),
    );
  }

  /**
   * Path part for operation stopLiveStream
   */
  static readonly StopLiveStreamPath = '/events/{eventGUID}/live_stream/stop';

  /**
   * Stop live stream of event.
   *
   * Stop live stream of event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stopLiveStream()` instead.
   *
   * This method doesn't expect any request body.
   */
  stopLiveStream$Response(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsLivestreamService.StopLiveStreamPath,
      'put',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Stop live stream of event.
   *
   * Stop live stream of event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stopLiveStream$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  stopLiveStream(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;
  }): Observable<ResponseSuccess> {
    return this.stopLiveStream$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }
}
