/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResponseSuccess } from '../models/response-success';
import { UploadVenuePhotoResponse } from '../models/upload-venue-photo-response';

@Injectable({
  providedIn: 'root',
})
export class VenuePhotoService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation venueVenuePhotosPut
   */
  static readonly VenueVenuePhotosPutPath = '/venue/venue_photos';

  /**
   * Upload venue photos.
   *
   * Upload venue photos
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `venueVenuePhotosPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  venueVenuePhotosPut$Response(params?: {}): Observable<
    StrictHttpResponse<UploadVenuePhotoResponse>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenuePhotoService.VenueVenuePhotosPutPath,
      'put',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UploadVenuePhotoResponse>;
        }),
      );
  }

  /**
   * Upload venue photos.
   *
   * Upload venue photos
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `venueVenuePhotosPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  venueVenuePhotosPut(params?: {}): Observable<UploadVenuePhotoResponse> {
    return this.venueVenuePhotosPut$Response(params).pipe(
      map(
        (r: StrictHttpResponse<UploadVenuePhotoResponse>) =>
          r.body as UploadVenuePhotoResponse,
      ),
    );
  }

  /**
   * Path part for operation venueVenuePhotosIdDelete
   */
  static readonly VenueVenuePhotosIdDeletePath = '/venue/venue_photos/{id}';

  /**
   * Remove venue photo.
   *
   * Remove venue photo
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `venueVenuePhotosIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  venueVenuePhotosIdDelete$Response(params: {
    /**
     * Venue Photo ID
     */
    id: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenuePhotoService.VenueVenuePhotosIdDeletePath,
      'delete',
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Remove venue photo.
   *
   * Remove venue photo
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `venueVenuePhotosIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  venueVenuePhotosIdDelete(params: {
    /**
     * Venue Photo ID
     */
    id: string;
  }): Observable<ResponseSuccess> {
    return this.venueVenuePhotosIdDelete$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }
}
