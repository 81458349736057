/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateCommentRequest } from '../models/create-comment-request';
import { EventCommentsCountResponseSuccess } from '../models/event-comments-count-response-success';
import { GetEventCommentsResponseSuccess } from '../models/get-event-comments-response-success';
import { ResponseSuccess } from '../models/response-success';

@Injectable({
  providedIn: 'root',
})
export class CommentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation createComment
   */
  static readonly CreateCommentPath = '/comments';

  /**
   * Create comment.
   *
   * create new comment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createComment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createComment$Response(params: {
    /**
     * current account type in account (Fan / Artist / Organizer / Venue)
     */
    'User-Account-Type': string;

    /**
     * Get
     */
    body: CreateCommentRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      CommentService.CreateCommentPath,
      'post',
    );
    if (params) {
      rb.header('User-Account-Type', params['User-Account-Type'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Create comment.
   *
   * create new comment
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createComment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createComment(params: {
    /**
     * current account type in account (Fan / Artist / Organizer / Venue)
     */
    'User-Account-Type': string;

    /**
     * Get
     */
    body: CreateCommentRequest;
  }): Observable<ResponseSuccess> {
    return this.createComment$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getEventComments
   */
  static readonly GetEventCommentsPath = '/events/{eventGUID}/comments';

  /**
   * Information about event comments.
   *
   * returns list of comments
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEventComments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventComments$Response(params: {
    /**
     * Event ID
     */
    eventGUID: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<StrictHttpResponse<GetEventCommentsResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      CommentService.GetEventCommentsPath,
      'get',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetEventCommentsResponseSuccess>;
        }),
      );
  }

  /**
   * Information about event comments.
   *
   * returns list of comments
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEventComments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventComments(params: {
    /**
     * Event ID
     */
    eventGUID: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<GetEventCommentsResponseSuccess> {
    return this.getEventComments$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetEventCommentsResponseSuccess>) =>
          r.body as GetEventCommentsResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getEventCommentsCount
   */
  static readonly GetEventCommentsCountPath =
    '/events/{eventGUID}/comments_count';

  /**
   * Events comments count.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEventCommentsCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventCommentsCount$Response(params: {
    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<StrictHttpResponse<EventCommentsCountResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      CommentService.GetEventCommentsCountPath,
      'get',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventCommentsCountResponseSuccess>;
        }),
      );
  }

  /**
   * Events comments count.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEventCommentsCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventCommentsCount(params: {
    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<EventCommentsCountResponseSuccess> {
    return this.getEventCommentsCount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<EventCommentsCountResponseSuccess>) =>
          r.body as EventCommentsCountResponseSuccess,
      ),
    );
  }
}
