/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateFanAccountRequest } from '../models/create-fan-account-request';
import { FanAccountsResponseSuccess } from '../models/fan-accounts-response-success';
import { FanProfileResponse } from '../models/fan-profile-response';
import { FollowersCount } from '../models/followers-count';
import { FollowingCount } from '../models/following-count';
import { GetFanAccountsResponseSuccess } from '../models/get-fan-accounts-response-success';
import { GetFanFollowingArtistResponseSuccess } from '../models/get-fan-following-artist-response-success';
import { GetFanFollowingArtistsResponseSuccess } from '../models/get-fan-following-artists-response-success';
import { GetFollowFlagRequest } from '../models/get-follow-flag-request';
import { GetFollowFlagResponse } from '../models/get-follow-flag-response';
import { GetGoingFansResponseSuccess } from '../models/get-going-fans-response-success';
import { ResponseSuccess } from '../models/response-success';
import { UpdateFanAccountRequest } from '../models/update-fan-account-request';

@Injectable({
  providedIn: 'root',
})
export class FanAccountService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getArtistFollowFlag
   */
  static readonly GetArtistFollowFlagPath = '/artist/follower';

  /**
   * returns the flag if this fan is following the ids specified in params.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArtistFollowFlag()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getArtistFollowFlag$Response(params: {
    /**
     * Get
     */
    body: GetFollowFlagRequest;
  }): Observable<StrictHttpResponse<GetFollowFlagResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.GetArtistFollowFlagPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetFollowFlagResponse>;
        }),
      );
  }

  /**
   * returns the flag if this fan is following the ids specified in params.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getArtistFollowFlag$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getArtistFollowFlag(params: {
    /**
     * Get
     */
    body: GetFollowFlagRequest;
  }): Observable<GetFollowFlagResponse> {
    return this.getArtistFollowFlag$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetFollowFlagResponse>) =>
          r.body as GetFollowFlagResponse,
      ),
    );
  }

  /**
   * Path part for operation updateFanAccount
   */
  static readonly UpdateFanAccountPath = '/fan';

  /**
   * Update fan account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFanAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFanAccount$Response(params: {
    /**
     * Get
     */
    body: UpdateFanAccountRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.UpdateFanAccountPath,
      'put',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Update fan account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFanAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFanAccount(params: {
    /**
     * Get
     */
    body: UpdateFanAccountRequest;
  }): Observable<ResponseSuccess> {
    return this.updateFanAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation createFanAccount
   */
  static readonly CreateFanAccountPath = '/fan';

  /**
   * Create fan account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFanAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFanAccount$Response(params: {
    /**
     * Get
     */
    body: CreateFanAccountRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.CreateFanAccountPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Create fan account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createFanAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFanAccount(params: {
    /**
     * Get
     */
    body: CreateFanAccountRequest;
  }): Observable<ResponseSuccess> {
    return this.createFanAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation goToEvent
   */
  static readonly GoToEventPath = '/fan/event/:eventId/going';

  /**
   * Fan account make going to event.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `goToEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  goToEvent$Response(params?: {}): Observable<
    StrictHttpResponse<GetGoingFansResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.GoToEventPath,
      'post',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetGoingFansResponseSuccess>;
        }),
      );
  }

  /**
   * Fan account make going to event.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `goToEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  goToEvent(params?: {}): Observable<GetGoingFansResponseSuccess> {
    return this.goToEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetGoingFansResponseSuccess>) =>
          r.body as GetGoingFansResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getFollowFlag
   */
  static readonly GetFollowFlagPath = '/fan/follower';

  /**
   * returns the flag if this fan is following the ids specified in params.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFollowFlag()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getFollowFlag$Response(params: {
    /**
     * Get
     */
    body: GetFollowFlagRequest;
  }): Observable<StrictHttpResponse<GetFollowFlagResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.GetFollowFlagPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetFollowFlagResponse>;
        }),
      );
  }

  /**
   * returns the flag if this fan is following the ids specified in params.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFollowFlag$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getFollowFlag(params: {
    /**
     * Get
     */
    body: GetFollowFlagRequest;
  }): Observable<GetFollowFlagResponse> {
    return this.getFollowFlag$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetFollowFlagResponse>) =>
          r.body as GetFollowFlagResponse,
      ),
    );
  }

  /**
   * Path part for operation getFanFollowingArtist
   */
  static readonly GetFanFollowingArtistPath =
    '/fan/follower/artist/artistAccountGUID';

  /**
   * Get fan following artist.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFanFollowingArtist()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFanFollowingArtist$Response(params?: {}): Observable<
    StrictHttpResponse<GetFanFollowingArtistResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.GetFanFollowingArtistPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetFanFollowingArtistResponseSuccess>;
        }),
      );
  }

  /**
   * Get fan following artist.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFanFollowingArtist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFanFollowingArtist(params?: {}): Observable<GetFanFollowingArtistResponseSuccess> {
    return this.getFanFollowingArtist$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetFanFollowingArtistResponseSuccess>) =>
          r.body as GetFanFollowingArtistResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation followFanArtist
   */
  static readonly FollowFanArtistPath =
    '/fan/follower/fan/artist/{artistAccountGUID}';

  /**
   * Fan follows an artist account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `followFanArtist()` instead.
   *
   * This method doesn't expect any request body.
   */
  followFanArtist$Response(params: {
    /**
     * Artist Account ID
     */
    artistAccountGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.FollowFanArtistPath,
      'post',
    );
    if (params) {
      rb.path('artistAccountGUID', params.artistAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Fan follows an artist account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `followFanArtist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  followFanArtist(params: {
    /**
     * Artist Account ID
     */
    artistAccountGUID: string;
  }): Observable<ResponseSuccess> {
    return this.followFanArtist$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation unfollowFanArtist
   */
  static readonly UnfollowFanArtistPath =
    '/fan/follower/fan/artist/artistAccountGUID';

  /**
   * Fan Unfollow an artist account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unfollowFanArtist()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowFanArtist$Response(params?: {}): Observable<
    StrictHttpResponse<ResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.UnfollowFanArtistPath,
      'delete',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Fan Unfollow an artist account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unfollowFanArtist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowFanArtist(params?: {}): Observable<ResponseSuccess> {
    return this.unfollowFanArtist$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation followFan
   */
  static readonly FollowFanPath = '/fan/follower/fan/fan/{fanAccountGUID}';

  /**
   * Fan follows fan account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `followFan()` instead.
   *
   * This method doesn't expect any request body.
   */
  followFan$Response(params: {
    /**
     * Fan Account ID
     */
    fanAccountGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.FollowFanPath,
      'post',
    );
    if (params) {
      rb.path('fanAccountGUID', params.fanAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Fan follows fan account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `followFan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  followFan(params: {
    /**
     * Fan Account ID
     */
    fanAccountGUID: string;
  }): Observable<ResponseSuccess> {
    return this.followFan$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation unfollowFan
   */
  static readonly UnfollowFanPath = '/fan/follower/fan/fan/fanAccountGUID';

  /**
   * Fan Unfollow fan account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unfollowFan()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowFan$Response(params?: {}): Observable<
    StrictHttpResponse<ResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.UnfollowFanPath,
      'delete',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Fan Unfollow fan account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unfollowFan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowFan(params?: {}): Observable<ResponseSuccess> {
    return this.unfollowFan$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation followFanOrganizer
   */
  static readonly FollowFanOrganizerPath =
    '/fan/follower/fan/organizer/{organizerAccountGUID}';

  /**
   * Fan follows an Organizer Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `followFanOrganizer()` instead.
   *
   * This method doesn't expect any request body.
   */
  followFanOrganizer$Response(params: {
    /**
     * Organizer Account ID
     */
    organizerAccountGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.FollowFanOrganizerPath,
      'post',
    );
    if (params) {
      rb.path('organizerAccountGUID', params.organizerAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Fan follows an Organizer Account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `followFanOrganizer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  followFanOrganizer(params: {
    /**
     * Organizer Account ID
     */
    organizerAccountGUID: string;
  }): Observable<ResponseSuccess> {
    return this.followFanOrganizer$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation unfollowFanOrganizer
   */
  static readonly UnfollowFanOrganizerPath =
    '/fan/follower/fan/organizer/organizerAccountGUID';

  /**
   * Fan Unfollow an Organizer Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unfollowFanOrganizer()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowFanOrganizer$Response(params?: {}): Observable<
    StrictHttpResponse<ResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.UnfollowFanOrganizerPath,
      'delete',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Fan Unfollow an Organizer Account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unfollowFanOrganizer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowFanOrganizer(params?: {}): Observable<ResponseSuccess> {
    return this.unfollowFanOrganizer$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation followFanVenue
   */
  static readonly FollowFanVenuePath =
    '/fan/follower/fan/venue/{venueAccountGUID}';

  /**
   * Fan follows an venue account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `followFanVenue()` instead.
   *
   * This method doesn't expect any request body.
   */
  followFanVenue$Response(params: {
    /**
     * Venue Account ID
     */
    venueAccountGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.FollowFanVenuePath,
      'post',
    );
    if (params) {
      rb.path('venueAccountGUID', params.venueAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Fan follows an venue account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `followFanVenue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  followFanVenue(params: {
    /**
     * Venue Account ID
     */
    venueAccountGUID: string;
  }): Observable<ResponseSuccess> {
    return this.followFanVenue$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation unfollowFanVenue
   */
  static readonly UnfollowFanVenuePath =
    '/fan/follower/fan/venue/venueAccountGUID';

  /**
   * Fan Unfollow an Organizer Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unfollowFanVenue()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowFanVenue$Response(params?: {}): Observable<
    StrictHttpResponse<ResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.UnfollowFanVenuePath,
      'delete',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Fan Unfollow an Organizer Account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unfollowFanVenue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowFanVenue(params?: {}): Observable<ResponseSuccess> {
    return this.unfollowFanVenue$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getOrganizerFollowFlag
   */
  static readonly GetOrganizerFollowFlagPath = '/fan/follower/organizer/';

  /**
   * returns the flag if this fan is following the ids specified in params.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrganizerFollowFlag()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOrganizerFollowFlag$Response(params: {
    /**
     * Get
     */
    body: GetFollowFlagRequest;
  }): Observable<StrictHttpResponse<GetFollowFlagResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.GetOrganizerFollowFlagPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetFollowFlagResponse>;
        }),
      );
  }

  /**
   * returns the flag if this fan is following the ids specified in params.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOrganizerFollowFlag$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getOrganizerFollowFlag(params: {
    /**
     * Get
     */
    body: GetFollowFlagRequest;
  }): Observable<GetFollowFlagResponse> {
    return this.getOrganizerFollowFlag$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetFollowFlagResponse>) =>
          r.body as GetFollowFlagResponse,
      ),
    );
  }

  /**
   * Path part for operation getVenueFollowFlag
   */
  static readonly GetVenueFollowFlagPath = '/fan/follower/venue/';

  /**
   * returns the flag if this fan is following the ids specified in params.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVenueFollowFlag()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getVenueFollowFlag$Response(params: {
    /**
     * Get
     */
    body: GetFollowFlagRequest;
  }): Observable<StrictHttpResponse<GetFollowFlagResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.GetVenueFollowFlagPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetFollowFlagResponse>;
        }),
      );
  }

  /**
   * returns the flag if this fan is following the ids specified in params.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVenueFollowFlag$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getVenueFollowFlag(params: {
    /**
     * Get
     */
    body: GetFollowFlagRequest;
  }): Observable<GetFollowFlagResponse> {
    return this.getVenueFollowFlag$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetFollowFlagResponse>) =>
          r.body as GetFollowFlagResponse,
      ),
    );
  }

  /**
   * Path part for operation getFanAccount
   */
  static readonly GetFanAccountPath = '/fan/profile';

  /**
   * returns all info that can be exposed to client.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFanAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFanAccount$Response(params?: {}): Observable<
    StrictHttpResponse<FanProfileResponse>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.GetFanAccountPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FanProfileResponse>;
        }),
      );
  }

  /**
   * returns all info that can be exposed to client.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFanAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFanAccount(params?: {}): Observable<FanProfileResponse> {
    return this.getFanAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<FanProfileResponse>) =>
          r.body as FanProfileResponse,
      ),
    );
  }

  /**
   * Path part for operation uploadProfilePhotoFanAccount
   */
  static readonly UploadProfilePhotoFanAccountPath = '/fan/profile_photo';

  /**
   * Upload profile photo.
   *
   * Upload profile photo to fan account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadProfilePhotoFanAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadProfilePhotoFanAccount$Response(params: {
    /**
     * multipart/form-data image&#x3D;file
     */
    query: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.UploadProfilePhotoFanAccountPath,
      'post',
    );
    if (params) {
      rb.query('query', params.query, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Upload profile photo.
   *
   * Upload profile photo to fan account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadProfilePhotoFanAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadProfilePhotoFanAccount(params: {
    /**
     * multipart/form-data image&#x3D;file
     */
    query: string;
  }): Observable<ResponseSuccess> {
    return this.uploadProfilePhotoFanAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation removeProfilePhotoFanAccount
   */
  static readonly RemoveProfilePhotoFanAccountPath = '/fan/profile_photo';

  /**
   * Remove profile photo.
   *
   * Remove profile photo to fan account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeProfilePhotoFanAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeProfilePhotoFanAccount$Response(params?: {}): Observable<
    StrictHttpResponse<ResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.RemoveProfilePhotoFanAccountPath,
      'delete',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Remove profile photo.
   *
   * Remove profile photo to fan account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeProfilePhotoFanAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeProfilePhotoFanAccount(params?: {}): Observable<ResponseSuccess> {
    return this.removeProfilePhotoFanAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getFanAccounts
   */
  static readonly GetFanAccountsPath = '/fans';

  /**
   * search for fans profiles.
   *
   * search for fans profiles by name or mouseliveId
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFanAccounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFanAccounts$Response(params?: {
    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * search string
     */
    search?: string;
  }): Observable<StrictHttpResponse<GetFanAccountsResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.GetFanAccountsPath,
      'get',
    );
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
      rb.query('search', params.search, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetFanAccountsResponseSuccess>;
        }),
      );
  }

  /**
   * search for fans profiles.
   *
   * search for fans profiles by name or mouseliveId
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFanAccounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFanAccounts(params?: {
    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * search string
     */
    search?: string;
  }): Observable<GetFanAccountsResponseSuccess> {
    return this.getFanAccounts$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetFanAccountsResponseSuccess>) =>
          r.body as GetFanAccountsResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getFanFollowers
   */
  static readonly GetFanFollowersPath = '/fans/{fanAccountGUID}/follower/fans';

  /**
   * Retrieves list of fan followers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFanFollowers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFanFollowers$Response(params: {
    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * Fan Account ID
     */
    fanAccountGUID: string;
  }): Observable<StrictHttpResponse<FanAccountsResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.GetFanFollowersPath,
      'get',
    );
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
      rb.path('fanAccountGUID', params.fanAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FanAccountsResponseSuccess>;
        }),
      );
  }

  /**
   * Retrieves list of fan followers.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFanFollowers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFanFollowers(params: {
    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * Fan Account ID
     */
    fanAccountGUID: string;
  }): Observable<FanAccountsResponseSuccess> {
    return this.getFanFollowers$Response(params).pipe(
      map(
        (r: StrictHttpResponse<FanAccountsResponseSuccess>) =>
          r.body as FanAccountsResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getFollowingFanAccounts
   */
  static readonly GetFollowingFanAccountsPath =
    '/fans/{fanAccountGUID}/following/artists';

  /**
   * Retrieves list of artists which are followed by the fan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFollowingFanAccounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFollowingFanAccounts$Response(params: {
    /**
     * Fan Account ID
     */
    fanAccountGUID: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<StrictHttpResponse<GetFanFollowingArtistsResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.GetFollowingFanAccountsPath,
      'get',
    );
    if (params) {
      rb.path('fanAccountGUID', params.fanAccountGUID, {});
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetFanFollowingArtistsResponseSuccess>;
        }),
      );
  }

  /**
   * Retrieves list of artists which are followed by the fan.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFollowingFanAccounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFollowingFanAccounts(params: {
    /**
     * Fan Account ID
     */
    fanAccountGUID: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<GetFanFollowingArtistsResponseSuccess> {
    return this.getFollowingFanAccounts$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetFanFollowingArtistsResponseSuccess>) =>
          r.body as GetFanFollowingArtistsResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getFollowingFan
   */
  static readonly GetFollowingFanPath = '/fans/{fanAccountGUID}/following/fans';

  /**
   * Retrieves list of fans which are followed by the fan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFollowingFan()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFollowingFan$Response(params: {
    /**
     * Fan Account ID
     */
    fanAccountGUID: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<StrictHttpResponse<FanAccountsResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.GetFollowingFanPath,
      'get',
    );
    if (params) {
      rb.path('fanAccountGUID', params.fanAccountGUID, {});
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FanAccountsResponseSuccess>;
        }),
      );
  }

  /**
   * Retrieves list of fans which are followed by the fan.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFollowingFan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFollowingFan(params: {
    /**
     * Fan Account ID
     */
    fanAccountGUID: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<FanAccountsResponseSuccess> {
    return this.getFollowingFan$Response(params).pipe(
      map(
        (r: StrictHttpResponse<FanAccountsResponseSuccess>) =>
          r.body as FanAccountsResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getFanAccountById
   */
  static readonly GetFanAccountByIdPath = '/fans/{id}';

  /**
   * returns all info that can be exposed to client.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFanAccountById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFanAccountById$Response(params: {
    /**
     * Fan Account ID
     */
    id: string;
  }): Observable<StrictHttpResponse<FanProfileResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.GetFanAccountByIdPath,
      'get',
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FanProfileResponse>;
        }),
      );
  }

  /**
   * returns all info that can be exposed to client.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFanAccountById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFanAccountById(params: {
    /**
     * Fan Account ID
     */
    id: string;
  }): Observable<FanProfileResponse> {
    return this.getFanAccountById$Response(params).pipe(
      map(
        (r: StrictHttpResponse<FanProfileResponse>) =>
          r.body as FanProfileResponse,
      ),
    );
  }

  /**
   * Path part for operation getFollowersCount
   */
  static readonly GetFollowersCountPath =
    '/fans/{userGUID}/fan/followers_count';

  /**
   * Followers comments count.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFollowersCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFollowersCount$Response(params: {
    /**
     * Fan Account ID
     */
    userGUID: string;
  }): Observable<StrictHttpResponse<FollowersCount>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.GetFollowersCountPath,
      'get',
    );
    if (params) {
      rb.path('userGUID', params.userGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FollowersCount>;
        }),
      );
  }

  /**
   * Followers comments count.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFollowersCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFollowersCount(params: {
    /**
     * Fan Account ID
     */
    userGUID: string;
  }): Observable<FollowersCount> {
    return this.getFollowersCount$Response(params).pipe(
      map((r: StrictHttpResponse<FollowersCount>) => r.body as FollowersCount),
    );
  }

  /**
   * Path part for operation getFanFollowingCount
   */
  static readonly GetFanFollowingCountPath =
    '/fans/{userGUID}/fan/following_count';

  /**
   * Following comments count.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFanFollowingCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFanFollowingCount$Response(params: {
    /**
     * Fan Account ID
     */
    userGUID: string;
  }): Observable<StrictHttpResponse<FollowingCount>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      FanAccountService.GetFanFollowingCountPath,
      'get',
    );
    if (params) {
      rb.path('userGUID', params.userGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FollowingCount>;
        }),
      );
  }

  /**
   * Following comments count.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFanFollowingCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFanFollowingCount(params: {
    /**
     * Fan Account ID
     */
    userGUID: string;
  }): Observable<FollowingCount> {
    return this.getFanFollowingCount$Response(params).pipe(
      map((r: StrictHttpResponse<FollowingCount>) => r.body as FollowingCount),
    );
  }
}
