/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateVideoLinkRequest } from '../models/create-video-link-request';
import { CreateVideoResponseSuccess } from '../models/create-video-response-success';
import { ResponseSuccess } from '../models/response-success';

@Injectable({
  providedIn: 'root',
})
export class VideoService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation createVideo
   */
  static readonly CreateVideoPath = '/videos';

  /**
   * Upload video.
   *
   * create video link or upload video
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createVideo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createVideo$Response(params: {
    /**
     * multipart/form-data image&#x3D;file
     */
    query: string;

    /**
     * Video Link
     */
    body: CreateVideoLinkRequest;
  }): Observable<StrictHttpResponse<CreateVideoResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      VideoService.CreateVideoPath,
      'post',
    );
    if (params) {
      rb.query('query', params.query, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreateVideoResponseSuccess>;
        }),
      );
  }

  /**
   * Upload video.
   *
   * create video link or upload video
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createVideo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createVideo(params: {
    /**
     * multipart/form-data image&#x3D;file
     */
    query: string;

    /**
     * Video Link
     */
    body: CreateVideoLinkRequest;
  }): Observable<CreateVideoResponseSuccess> {
    return this.createVideo$Response(params).pipe(
      map(
        (r: StrictHttpResponse<CreateVideoResponseSuccess>) =>
          r.body as CreateVideoResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation uploadVideo
   */
  static readonly UploadVideoPath = '/videos/{videoGUID}';

  /**
   * Remove video.
   *
   * removes video by ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadVideo()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadVideo$Response(params: {
    /**
     * Video ID
     */
    videoGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      VideoService.UploadVideoPath,
      'delete',
    );
    if (params) {
      rb.path('videoGUID', params.videoGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Remove video.
   *
   * removes video by ID
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadVideo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadVideo(params: {
    /**
     * Video ID
     */
    videoGUID: string;
  }): Observable<ResponseSuccess> {
    return this.uploadVideo$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }
}
