/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateCrowdfundingPaymentRequest } from '../models/create-crowdfunding-payment-request';
import { CreatePaymentRequest } from '../models/create-payment-request';
import { CreatePaymentResponseSuccess } from '../models/create-payment-response-success';
import { ResponseSuccess } from '../models/response-success';
import { UpdateCrowdfundingPaymentRequest } from '../models/update-crowdfunding-payment-request';

@Injectable({
  providedIn: 'root',
})
export class PaymentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation updateCrowdfundingPayment
   */
  static readonly UpdateCrowdfundingPaymentPath = '/payments/{id}/crowdfunding';

  /**
   * Update crowdfunding payment.
   *
   * Update payment method to crowdfunding payment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCrowdfundingPayment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCrowdfundingPayment$Response(params: {
    /**
     * Payment ID
     */
    id: string;

    /**
     * Get
     */
    body: UpdateCrowdfundingPaymentRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      PaymentService.UpdateCrowdfundingPaymentPath,
      'put',
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Update crowdfunding payment.
   *
   * Update payment method to crowdfunding payment
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCrowdfundingPayment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCrowdfundingPayment(params: {
    /**
     * Payment ID
     */
    id: string;

    /**
     * Get
     */
    body: UpdateCrowdfundingPaymentRequest;
  }): Observable<ResponseSuccess> {
    return this.updateCrowdfundingPayment$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation createCrowdfundingPayment
   */
  static readonly CreateCrowdfundingPaymentPath = '/payments/crowdfunding';

  /**
   * Create crowdfunding payment.
   *
   * Create new payment for crowdfunding
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCrowdfundingPayment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCrowdfundingPayment$Response(params: {
    /**
     * Get
     */
    body: CreateCrowdfundingPaymentRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      PaymentService.CreateCrowdfundingPaymentPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Create crowdfunding payment.
   *
   * Create new payment for crowdfunding
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createCrowdfundingPayment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCrowdfundingPayment(params: {
    /**
     * Get
     */
    body: CreateCrowdfundingPaymentRequest;
  }): Observable<ResponseSuccess> {
    return this.createCrowdfundingPayment$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getPaymentsHistory
   */
  static readonly GetPaymentsHistoryPath = '/payments/history';

  /**
   * Get payments history.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentsHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentsHistory$Response(params?: {}): Observable<
    StrictHttpResponse<ResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      PaymentService.GetPaymentsHistoryPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Get payments history.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPaymentsHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentsHistory(params?: {}): Observable<ResponseSuccess> {
    return this.getPaymentsHistory$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation createPayment
   */
  static readonly CreatePaymentPath = '/payments/regular';

  /**
   * Create regular payment.
   *
   * more information about payment intent here https://stripe.com/docs/payments/accept-a-payment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPayment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPayment$Response(params: {
    /**
     * Get
     */
    body: CreatePaymentRequest;
  }): Observable<StrictHttpResponse<CreatePaymentResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      PaymentService.CreatePaymentPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePaymentResponseSuccess>;
        }),
      );
  }

  /**
   * Create regular payment.
   *
   * more information about payment intent here https://stripe.com/docs/payments/accept-a-payment
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createPayment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPayment(params: {
    /**
     * Get
     */
    body: CreatePaymentRequest;
  }): Observable<CreatePaymentResponseSuccess> {
    return this.createPayment$Response(params).pipe(
      map(
        (r: StrictHttpResponse<CreatePaymentResponseSuccess>) =>
          r.body as CreatePaymentResponseSuccess,
      ),
    );
  }
}
