/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EventArtistAccountsResponse } from '../models/event-artist-accounts-response';
import { EventVenueAccountsResponse } from '../models/event-venue-accounts-response';

@Injectable({
  providedIn: 'root',
})
export class SearchEventService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation artistAccountsEventSearch
   */
  static readonly ArtistAccountsEventSearchPath = '/search/artist/event';

  /**
   * Information about artist accounts with private info for event.
   *
   * returns list of  artist accounts with private info for event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artistAccountsEventSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  artistAccountsEventSearch$Response(params?: {
    /**
     * search string
     */
    search?: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * City
     */
    city?: string;

    /**
     * State
     */
    state?: string;

    /**
     * Country
     */
    country?: string;

    /**
     * Venue types array.  Example: &#x27;100,400&#x27;
     */
    price?: string;

    /**
     * Time duration.  Example: &#x27;15&#x27;
     */
    timeFor?: string;

    /**
     * Genres array.  Example: &#x27;Rap,RNB&#x27;
     */
    genres?: string;

    /**
     * Latitude
     */
    latitude?: string;

    /**
     * Longitude
     */
    longitude?: string;

    /**
     * Distance
     */
    distance?: string;
  }): Observable<StrictHttpResponse<EventArtistAccountsResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      SearchEventService.ArtistAccountsEventSearchPath,
      'get',
    );
    if (params) {
      rb.query('search', params.search, {});
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
      rb.query('city', params.city, {});
      rb.query('state', params.state, {});
      rb.query('country', params.country, {});
      rb.query('price', params.price, {});
      rb.query('timeFor', params.timeFor, {});
      rb.query('genres', params.genres, {});
      rb.query('latitude', params.latitude, {});
      rb.query('longitude', params.longitude, {});
      rb.query('distance', params.distance, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventArtistAccountsResponse>;
        }),
      );
  }

  /**
   * Information about artist accounts with private info for event.
   *
   * returns list of  artist accounts with private info for event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `artistAccountsEventSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  artistAccountsEventSearch(params?: {
    /**
     * search string
     */
    search?: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * City
     */
    city?: string;

    /**
     * State
     */
    state?: string;

    /**
     * Country
     */
    country?: string;

    /**
     * Venue types array.  Example: &#x27;100,400&#x27;
     */
    price?: string;

    /**
     * Time duration.  Example: &#x27;15&#x27;
     */
    timeFor?: string;

    /**
     * Genres array.  Example: &#x27;Rap,RNB&#x27;
     */
    genres?: string;

    /**
     * Latitude
     */
    latitude?: string;

    /**
     * Longitude
     */
    longitude?: string;

    /**
     * Distance
     */
    distance?: string;
  }): Observable<EventArtistAccountsResponse> {
    return this.artistAccountsEventSearch$Response(params).pipe(
      map(
        (r: StrictHttpResponse<EventArtistAccountsResponse>) =>
          r.body as EventArtistAccountsResponse,
      ),
    );
  }

  /**
   * Path part for operation venueAccountsEventSearch
   */
  static readonly VenueAccountsEventSearchPath = '/search/venue/event';

  /**
   * Information about venue accounts with private info for event.
   *
   * returns list of  venue accounts with private info for event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `venueAccountsEventSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  venueAccountsEventSearch$Response(params?: {
    /**
     * search string
     */
    search?: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * City
     */
    city?: string;

    /**
     * State
     */
    state?: string;

    /**
     * ZipCode
     */
    zipCode?: string;

    /**
     * Venue types array.  Example: &#x27;100,400&#x27;
     */
    price?: string;

    /**
     * Venue types array.  Example: &#x27;1000,5000&#x27;
     */
    capacity?: string;

    /**
     * Venue types array.  Example: &#x27;VR,General&#x27;
     */
    types?: string;

    /**
     * Latitude
     */
    latitude?: string;

    /**
     * Longitude
     */
    longitude?: string;

    /**
     * Distance
     */
    distance?: string;
  }): Observable<StrictHttpResponse<EventVenueAccountsResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      SearchEventService.VenueAccountsEventSearchPath,
      'get',
    );
    if (params) {
      rb.query('search', params.search, {});
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
      rb.query('city', params.city, {});
      rb.query('state', params.state, {});
      rb.query('zipCode', params.zipCode, {});
      rb.query('price', params.price, {});
      rb.query('capacity', params.capacity, {});
      rb.query('types', params.types, {});
      rb.query('latitude', params.latitude, {});
      rb.query('longitude', params.longitude, {});
      rb.query('distance', params.distance, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventVenueAccountsResponse>;
        }),
      );
  }

  /**
   * Information about venue accounts with private info for event.
   *
   * returns list of  venue accounts with private info for event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `venueAccountsEventSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  venueAccountsEventSearch(params?: {
    /**
     * search string
     */
    search?: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * City
     */
    city?: string;

    /**
     * State
     */
    state?: string;

    /**
     * ZipCode
     */
    zipCode?: string;

    /**
     * Venue types array.  Example: &#x27;100,400&#x27;
     */
    price?: string;

    /**
     * Venue types array.  Example: &#x27;1000,5000&#x27;
     */
    capacity?: string;

    /**
     * Venue types array.  Example: &#x27;VR,General&#x27;
     */
    types?: string;

    /**
     * Latitude
     */
    latitude?: string;

    /**
     * Longitude
     */
    longitude?: string;

    /**
     * Distance
     */
    distance?: string;
  }): Observable<EventVenueAccountsResponse> {
    return this.venueAccountsEventSearch$Response(params).pipe(
      map(
        (r: StrictHttpResponse<EventVenueAccountsResponse>) =>
          r.body as EventVenueAccountsResponse,
      ),
    );
  }
}
