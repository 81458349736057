/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ArtistAccountStatusResponse } from '../models/artist-account-status-response';
import { ArtistProfileResponse } from '../models/artist-profile-response';
import { CreateArtistAccountCalendarItemResponseSuccess } from '../models/create-artist-account-calendar-item-response-success';
import { CreateArtistAccountRequest } from '../models/create-artist-account-request';
import { CreateCalendarItemRequest } from '../models/create-calendar-item-request';
import { CreateOrUpdateArtistAccountRiderRequest } from '../models/create-or-update-artist-account-rider-request';
import { CreateOrUpdateBookingSettingsRequest } from '../models/create-or-update-booking-settings-request';
import { CreateOrUpdateCalendarSettingsRequest } from '../models/create-or-update-calendar-settings-request';
import { DeleteArtistAccountCalendarItemResponseSuccess } from '../models/delete-artist-account-calendar-item-response-success';
import { FollowersCount } from '../models/followers-count';
import { FollowingCount } from '../models/following-count';
import { GetArtistAccountBookingSettingsResponseSuccess } from '../models/get-artist-account-booking-settings-response-success';
import { GetArtistAccountCalendarResponseSuccess } from '../models/get-artist-account-calendar-response-success';
import { GetArtistAccountFollowersResponseSuccess } from '../models/get-artist-account-followers-response-success';
import { GetArtistAccountInfoResponseSuccess } from '../models/get-artist-account-info-response-success';
import { GetArtistAccountRiderResponseSuccess } from '../models/get-artist-account-rider-response-success';
import { GetArtistAccountsResponseSuccess } from '../models/get-artist-accounts-response-success';
import { GetArtistMediaResponse } from '../models/get-artist-media-response';
import { JoinToStripeRequest } from '../models/join-to-stripe-request';
import { ResponseSuccess } from '../models/response-success';
import { UpdateArtistAccountBookingSettingsResponseSuccess } from '../models/update-artist-account-booking-settings-response-success';
import { UpdateArtistAccountCalendarSettingsResponseSuccess } from '../models/update-artist-account-calendar-settings-response-success';
import { UpdateArtistAccountRequest } from '../models/update-artist-account-request';

@Injectable({
  providedIn: 'root',
})
export class ArtistAccountService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation updateArtistAccount
   */
  static readonly UpdateArtistAccountPath = '/artist';

  /**
   * Update artist account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateArtistAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateArtistAccount$Response(params: {
    /**
     * Get
     */
    body: UpdateArtistAccountRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.UpdateArtistAccountPath,
      'put',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Update artist account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateArtistAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateArtistAccount(params: {
    /**
     * Get
     */
    body: UpdateArtistAccountRequest;
  }): Observable<ResponseSuccess> {
    return this.updateArtistAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation createArtistAccount
   */
  static readonly CreateArtistAccountPath = '/artist';

  /**
   * Create artist account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createArtistAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createArtistAccount$Response(params: {
    /**
     * Get
     */
    body: CreateArtistAccountRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.CreateArtistAccountPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Create artist account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createArtistAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createArtistAccount(params: {
    /**
     * Get
     */
    body: CreateArtistAccountRequest;
  }): Observable<ResponseSuccess> {
    return this.createArtistAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation deleteArtistPhotoArtistAccounts
   */
  static readonly DeleteArtistPhotoArtistAccountsPath =
    '/artist/artist_photo/{photoID}';

  /**
   * Remove artist photo.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteArtistPhotoArtistAccounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteArtistPhotoArtistAccounts$Response(params: {
    photoID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.DeleteArtistPhotoArtistAccountsPath,
      'delete',
    );
    if (params) {
      rb.path('photoID', params.photoID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Remove artist photo.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteArtistPhotoArtistAccounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteArtistPhotoArtistAccounts(params: {
    photoID: string;
  }): Observable<ResponseSuccess> {
    return this.deleteArtistPhotoArtistAccounts$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation uploadArtistPhotoArtistAccounts
   */
  static readonly UploadArtistPhotoArtistAccountsPath = '/artist/artist_photo';

  /**
   * Upload artist photo.
   *
   * Upload artist photo to artist account. Only PNG and JPG, 10MB max.
   * photo description isn't required, but max length of it is 100 symbols.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadArtistPhotoArtistAccounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadArtistPhotoArtistAccounts$Response(params: {
    /**
     * multipart/form-data image&#x3D;file description&#x3D;...
     */
    query: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.UploadArtistPhotoArtistAccountsPath,
      'post',
    );
    if (params) {
      rb.query('query', params.query, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Upload artist photo.
   *
   * Upload artist photo to artist account. Only PNG and JPG, 10MB max.
   * photo description isn't required, but max length of it is 100 symbols.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadArtistPhotoArtistAccounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadArtistPhotoArtistAccounts(params: {
    /**
     * multipart/form-data image&#x3D;file description&#x3D;...
     */
    query: string;
  }): Observable<ResponseSuccess> {
    return this.uploadArtistPhotoArtistAccounts$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getArtistBookingSettings
   */
  static readonly GetArtistBookingSettingsPath = '/artist/booking_settings';

  /**
   * Retrieves artist booking settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArtistBookingSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistBookingSettings$Response(params?: {}): Observable<
    StrictHttpResponse<GetArtistAccountBookingSettingsResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.GetArtistBookingSettingsPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetArtistAccountBookingSettingsResponseSuccess>;
        }),
      );
  }

  /**
   * Retrieves artist booking settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getArtistBookingSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistBookingSettings(params?: {}): Observable<GetArtistAccountBookingSettingsResponseSuccess> {
    return this.getArtistBookingSettings$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<GetArtistAccountBookingSettingsResponseSuccess>,
        ) => r.body as GetArtistAccountBookingSettingsResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation updateBookingSettings
   */
  static readonly UpdateBookingSettingsPath = '/artist/booking_settings';

  /**
   * Updates booking settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBookingSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBookingSettings$Response(params: {
    /**
     * Put
     */
    body: CreateOrUpdateBookingSettingsRequest;
  }): Observable<
    StrictHttpResponse<UpdateArtistAccountBookingSettingsResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.UpdateBookingSettingsPath,
      'put',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UpdateArtistAccountBookingSettingsResponseSuccess>;
        }),
      );
  }

  /**
   * Updates booking settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateBookingSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBookingSettings(params: {
    /**
     * Put
     */
    body: CreateOrUpdateBookingSettingsRequest;
  }): Observable<UpdateArtistAccountBookingSettingsResponseSuccess> {
    return this.updateBookingSettings$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<UpdateArtistAccountBookingSettingsResponseSuccess>,
        ) => r.body as UpdateArtistAccountBookingSettingsResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation removeArtistAccount
   */
  static readonly RemoveArtistAccountPath = '/artist/delete/{artistGUID}';

  /**
   * Remove artist account.
   *
   * Remove artist account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeArtistAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeArtistAccount$Response(params: {
    /**
     * Account ID
     */
    artistGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.RemoveArtistAccountPath,
      'delete',
    );
    if (params) {
      rb.path('artistGUID', params.artistGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Remove artist account.
   *
   * Remove artist account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeArtistAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeArtistAccount(params: {
    /**
     * Account ID
     */
    artistGUID: string;
  }): Observable<ResponseSuccess> {
    return this.removeArtistAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getArtist
   */
  static readonly GetArtistPath = '/artist/get/{id}';

  /**
   * get artist account by id.
   *
   * get artist account by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArtist()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtist$Response(params: {
    /**
     * artist account ID
     */
    id: string;
  }): Observable<StrictHttpResponse<ArtistProfileResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.GetArtistPath,
      'get',
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ArtistProfileResponse>;
        }),
      );
  }

  /**
   * get artist account by id.
   *
   * get artist account by its id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getArtist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtist(params: {
    /**
     * artist account ID
     */
    id: string;
  }): Observable<ArtistProfileResponse> {
    return this.getArtist$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ArtistProfileResponse>) =>
          r.body as ArtistProfileResponse,
      ),
    );
  }

  /**
   * Path part for operation getArtistAccount
   */
  static readonly GetArtistAccountPath = '/artist/profile';

  /**
   * returns all info that can be exposed to client.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArtistAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistAccount$Response(params?: {}): Observable<
    StrictHttpResponse<GetArtistAccountInfoResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.GetArtistAccountPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetArtistAccountInfoResponseSuccess>;
        }),
      );
  }

  /**
   * returns all info that can be exposed to client.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getArtistAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistAccount(params?: {}): Observable<GetArtistAccountInfoResponseSuccess> {
    return this.getArtistAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetArtistAccountInfoResponseSuccess>) =>
          r.body as GetArtistAccountInfoResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation uploadProfilePhotoArtistAccount
   */
  static readonly UploadProfilePhotoArtistAccountPath = '/artist/profile_photo';

  /**
   * Upload profile photo.
   *
   * Upload profile photo to artist account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadProfilePhotoArtistAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadProfilePhotoArtistAccount$Response(params: {
    /**
     * multipart/form-data image&#x3D;file
     */
    query: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.UploadProfilePhotoArtistAccountPath,
      'post',
    );
    if (params) {
      rb.query('query', params.query, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Upload profile photo.
   *
   * Upload profile photo to artist account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadProfilePhotoArtistAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadProfilePhotoArtistAccount(params: {
    /**
     * multipart/form-data image&#x3D;file
     */
    query: string;
  }): Observable<ResponseSuccess> {
    return this.uploadProfilePhotoArtistAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation removeProfilePhotoArtistAccount
   */
  static readonly RemoveProfilePhotoArtistAccountPath = '/artist/profile_photo';

  /**
   * Remove profile photo.
   *
   * Remove profile photo to artist account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeProfilePhotoArtistAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeProfilePhotoArtistAccount$Response(params?: {}): Observable<
    StrictHttpResponse<ResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.RemoveProfilePhotoArtistAccountPath,
      'delete',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Remove profile photo.
   *
   * Remove profile photo to artist account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeProfilePhotoArtistAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeProfilePhotoArtistAccount(params?: {}): Observable<ResponseSuccess> {
    return this.removeProfilePhotoArtistAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation reviewArtistAccount
   */
  static readonly ReviewArtistAccountPath = '/artist/review';

  /**
   * Sends artist account for review and returns it's current status.
   *
   * Review artist account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reviewArtistAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  reviewArtistAccount$Response(params?: {}): Observable<
    StrictHttpResponse<ArtistAccountStatusResponse>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.ReviewArtistAccountPath,
      'put',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ArtistAccountStatusResponse>;
        }),
      );
  }

  /**
   * Sends artist account for review and returns it's current status.
   *
   * Review artist account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reviewArtistAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reviewArtistAccount(params?: {}): Observable<ArtistAccountStatusResponse> {
    return this.reviewArtistAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ArtistAccountStatusResponse>) =>
          r.body as ArtistAccountStatusResponse,
      ),
    );
  }

  /**
   * Path part for operation getArtistRider
   */
  static readonly GetArtistRiderPath = '/artist/rider';

  /**
   * Get rider.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArtistRider()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistRider$Response(params?: {}): Observable<
    StrictHttpResponse<GetArtistAccountRiderResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.GetArtistRiderPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetArtistAccountRiderResponseSuccess>;
        }),
      );
  }

  /**
   * Get rider.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getArtistRider$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistRider(params?: {}): Observable<GetArtistAccountRiderResponseSuccess> {
    return this.getArtistRider$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetArtistAccountRiderResponseSuccess>) =>
          r.body as GetArtistAccountRiderResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation updateArtistRider
   */
  static readonly UpdateArtistRiderPath = '/artist/rider';

  /**
   * Updates rider.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateArtistRider()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateArtistRider$Response(params: {
    /**
     * Put
     */
    body: CreateOrUpdateArtistAccountRiderRequest;
  }): Observable<StrictHttpResponse<GetArtistAccountRiderResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.UpdateArtistRiderPath,
      'put',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetArtistAccountRiderResponseSuccess>;
        }),
      );
  }

  /**
   * Updates rider.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateArtistRider$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateArtistRider(params: {
    /**
     * Put
     */
    body: CreateOrUpdateArtistAccountRiderRequest;
  }): Observable<GetArtistAccountRiderResponseSuccess> {
    return this.updateArtistRider$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetArtistAccountRiderResponseSuccess>) =>
          r.body as GetArtistAccountRiderResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getBackstageRiderPdf
   */
  static readonly GetBackstageRiderPdfPath = '/artist/rider/backstage/pdf';

  /**
   * Get backstage rider pdf.
   *
   * Get backstage rider pdf file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBackstageRiderPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBackstageRiderPdf$Response(params?: {}): Observable<
    StrictHttpResponse<GetArtistAccountRiderResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.GetBackstageRiderPdfPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetArtistAccountRiderResponseSuccess>;
        }),
      );
  }

  /**
   * Get backstage rider pdf.
   *
   * Get backstage rider pdf file
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBackstageRiderPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBackstageRiderPdf(params?: {}): Observable<GetArtistAccountRiderResponseSuccess> {
    return this.getBackstageRiderPdf$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetArtistAccountRiderResponseSuccess>) =>
          r.body as GetArtistAccountRiderResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation uploadBackstageRiderPdf
   */
  static readonly UploadBackstageRiderPdfPath = '/artist/rider/backstage/pdf';

  /**
   * Upload backstage rider pdf.
   *
   * Upload backstage rider pdf file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadBackstageRiderPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadBackstageRiderPdf$Response(params: {
    /**
     * multipart/form-data file&#x3D;file
     */
    query: string;
  }): Observable<StrictHttpResponse<GetArtistAccountRiderResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.UploadBackstageRiderPdfPath,
      'post',
    );
    if (params) {
      rb.query('query', params.query, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetArtistAccountRiderResponseSuccess>;
        }),
      );
  }

  /**
   * Upload backstage rider pdf.
   *
   * Upload backstage rider pdf file
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadBackstageRiderPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadBackstageRiderPdf(params: {
    /**
     * multipart/form-data file&#x3D;file
     */
    query: string;
  }): Observable<GetArtistAccountRiderResponseSuccess> {
    return this.uploadBackstageRiderPdf$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetArtistAccountRiderResponseSuccess>) =>
          r.body as GetArtistAccountRiderResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation removeBackstageRiderPdf
   */
  static readonly RemoveBackstageRiderPdfPath = '/artist/rider/backstage/pdf';

  /**
   * Remove backstage rider pdf.
   *
   * Remove backstage rider pdf file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeBackstageRiderPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeBackstageRiderPdf$Response(params?: {}): Observable<
    StrictHttpResponse<GetArtistAccountRiderResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.RemoveBackstageRiderPdfPath,
      'delete',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetArtistAccountRiderResponseSuccess>;
        }),
      );
  }

  /**
   * Remove backstage rider pdf.
   *
   * Remove backstage rider pdf file
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeBackstageRiderPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeBackstageRiderPdf(params?: {}): Observable<GetArtistAccountRiderResponseSuccess> {
    return this.removeBackstageRiderPdf$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetArtistAccountRiderResponseSuccess>) =>
          r.body as GetArtistAccountRiderResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getHospitalityRiderPdf
   */
  static readonly GetHospitalityRiderPdfPath = '/artist/rider/hospitality/pdf';

  /**
   * Get hospitality rider pdf.
   *
   * Get hospitality rider pdf file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHospitalityRiderPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHospitalityRiderPdf$Response(params?: {}): Observable<
    StrictHttpResponse<GetArtistAccountRiderResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.GetHospitalityRiderPdfPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetArtistAccountRiderResponseSuccess>;
        }),
      );
  }

  /**
   * Get hospitality rider pdf.
   *
   * Get hospitality rider pdf file
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHospitalityRiderPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHospitalityRiderPdf(params?: {}): Observable<GetArtistAccountRiderResponseSuccess> {
    return this.getHospitalityRiderPdf$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetArtistAccountRiderResponseSuccess>) =>
          r.body as GetArtistAccountRiderResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation uploadHospitalityRiderPdf
   */
  static readonly UploadHospitalityRiderPdfPath =
    '/artist/rider/hospitality/pdf';

  /**
   * Upload hospitality rider pdf.
   *
   * Upload hospitality rider pdf file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadHospitalityRiderPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadHospitalityRiderPdf$Response(params: {
    /**
     * multipart/form-data file&#x3D;file
     */
    query: string;
  }): Observable<StrictHttpResponse<GetArtistAccountRiderResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.UploadHospitalityRiderPdfPath,
      'post',
    );
    if (params) {
      rb.query('query', params.query, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetArtistAccountRiderResponseSuccess>;
        }),
      );
  }

  /**
   * Upload hospitality rider pdf.
   *
   * Upload hospitality rider pdf file
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadHospitalityRiderPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadHospitalityRiderPdf(params: {
    /**
     * multipart/form-data file&#x3D;file
     */
    query: string;
  }): Observable<GetArtistAccountRiderResponseSuccess> {
    return this.uploadHospitalityRiderPdf$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetArtistAccountRiderResponseSuccess>) =>
          r.body as GetArtistAccountRiderResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation removeHospitalityRiderPdf
   */
  static readonly RemoveHospitalityRiderPdfPath =
    '/artist/rider/hospitality/pdf';

  /**
   * Remove hospitality rider pdf.
   *
   * Remove hospitality rider pdf file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeHospitalityRiderPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeHospitalityRiderPdf$Response(params?: {}): Observable<
    StrictHttpResponse<GetArtistAccountRiderResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.RemoveHospitalityRiderPdfPath,
      'delete',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetArtistAccountRiderResponseSuccess>;
        }),
      );
  }

  /**
   * Remove hospitality rider pdf.
   *
   * Remove hospitality rider pdf file
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeHospitalityRiderPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeHospitalityRiderPdf(params?: {}): Observable<GetArtistAccountRiderResponseSuccess> {
    return this.removeHospitalityRiderPdf$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetArtistAccountRiderResponseSuccess>) =>
          r.body as GetArtistAccountRiderResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getStageRiderPdf
   */
  static readonly GetStageRiderPdfPath = '/artist/rider/stage/pdf';

  /**
   * Get stage rider pdf.
   *
   * Get stage rider pdf file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStageRiderPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStageRiderPdf$Response(params?: {}): Observable<
    StrictHttpResponse<GetArtistAccountRiderResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.GetStageRiderPdfPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetArtistAccountRiderResponseSuccess>;
        }),
      );
  }

  /**
   * Get stage rider pdf.
   *
   * Get stage rider pdf file
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStageRiderPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStageRiderPdf(params?: {}): Observable<GetArtistAccountRiderResponseSuccess> {
    return this.getStageRiderPdf$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetArtistAccountRiderResponseSuccess>) =>
          r.body as GetArtistAccountRiderResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation uploadStageRiderPdf
   */
  static readonly UploadStageRiderPdfPath = '/artist/rider/stage/pdf';

  /**
   * Upload stage rider pdf.
   *
   * Upload backstage rider pdf file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadStageRiderPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadStageRiderPdf$Response(params: {
    /**
     * multipart/form-data file&#x3D;file
     */
    query: string;
  }): Observable<StrictHttpResponse<GetArtistAccountRiderResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.UploadStageRiderPdfPath,
      'post',
    );
    if (params) {
      rb.query('query', params.query, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetArtistAccountRiderResponseSuccess>;
        }),
      );
  }

  /**
   * Upload stage rider pdf.
   *
   * Upload backstage rider pdf file
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadStageRiderPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadStageRiderPdf(params: {
    /**
     * multipart/form-data file&#x3D;file
     */
    query: string;
  }): Observable<GetArtistAccountRiderResponseSuccess> {
    return this.uploadStageRiderPdf$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetArtistAccountRiderResponseSuccess>) =>
          r.body as GetArtistAccountRiderResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation removeStageRiderPdf
   */
  static readonly RemoveStageRiderPdfPath = '/artist/rider/stage/pdf';

  /**
   * Remove stage rider pdf.
   *
   * Remove stage rider pdf file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeStageRiderPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeStageRiderPdf$Response(params?: {}): Observable<
    StrictHttpResponse<GetArtistAccountRiderResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.RemoveStageRiderPdfPath,
      'delete',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetArtistAccountRiderResponseSuccess>;
        }),
      );
  }

  /**
   * Remove stage rider pdf.
   *
   * Remove stage rider pdf file
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeStageRiderPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeStageRiderPdf(params?: {}): Observable<GetArtistAccountRiderResponseSuccess> {
    return this.removeStageRiderPdf$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetArtistAccountRiderResponseSuccess>) =>
          r.body as GetArtistAccountRiderResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getTechnicalRiderPdf
   */
  static readonly GetTechnicalRiderPdfPath = '/artist/rider/technical/pdf';

  /**
   * Get technical rider pdf.
   *
   * Get technical rider pdf file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTechnicalRiderPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTechnicalRiderPdf$Response(params?: {}): Observable<
    StrictHttpResponse<GetArtistAccountRiderResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.GetTechnicalRiderPdfPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetArtistAccountRiderResponseSuccess>;
        }),
      );
  }

  /**
   * Get technical rider pdf.
   *
   * Get technical rider pdf file
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTechnicalRiderPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTechnicalRiderPdf(params?: {}): Observable<GetArtistAccountRiderResponseSuccess> {
    return this.getTechnicalRiderPdf$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetArtistAccountRiderResponseSuccess>) =>
          r.body as GetArtistAccountRiderResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation uploadTechnicalRiderPdf
   */
  static readonly UploadTechnicalRiderPdfPath = '/artist/rider/technical/pdf';

  /**
   * Upload technical rider pdf.
   *
   * Upload technical rider pdf file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadTechnicalRiderPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadTechnicalRiderPdf$Response(params: {
    /**
     * multipart/form-data file&#x3D;file
     */
    query: string;
  }): Observable<StrictHttpResponse<GetArtistAccountRiderResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.UploadTechnicalRiderPdfPath,
      'post',
    );
    if (params) {
      rb.query('query', params.query, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetArtistAccountRiderResponseSuccess>;
        }),
      );
  }

  /**
   * Upload technical rider pdf.
   *
   * Upload technical rider pdf file
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadTechnicalRiderPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadTechnicalRiderPdf(params: {
    /**
     * multipart/form-data file&#x3D;file
     */
    query: string;
  }): Observable<GetArtistAccountRiderResponseSuccess> {
    return this.uploadTechnicalRiderPdf$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetArtistAccountRiderResponseSuccess>) =>
          r.body as GetArtistAccountRiderResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation removeTechnicalRiderPdf
   */
  static readonly RemoveTechnicalRiderPdfPath = '/artist/rider/technical/pdf';

  /**
   * Remove technical rider pdf.
   *
   * Remove technical rider pdf file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeTechnicalRiderPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeTechnicalRiderPdf$Response(params?: {}): Observable<
    StrictHttpResponse<GetArtistAccountRiderResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.RemoveTechnicalRiderPdfPath,
      'delete',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetArtistAccountRiderResponseSuccess>;
        }),
      );
  }

  /**
   * Remove technical rider pdf.
   *
   * Remove technical rider pdf file
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeTechnicalRiderPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeTechnicalRiderPdf(params?: {}): Observable<GetArtistAccountRiderResponseSuccess> {
    return this.removeTechnicalRiderPdf$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetArtistAccountRiderResponseSuccess>) =>
          r.body as GetArtistAccountRiderResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation statusArtistAccount
   */
  static readonly StatusArtistAccountPath = '/artist/status';

  /**
   * returns artist account status.
   *
   * artist account status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statusArtistAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusArtistAccount$Response(params?: {}): Observable<
    StrictHttpResponse<ResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.StatusArtistAccountPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * returns artist account status.
   *
   * artist account status
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `statusArtistAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusArtistAccount(params?: {}): Observable<ResponseSuccess> {
    return this.statusArtistAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation artistJoinToStripe
   */
  static readonly ArtistJoinToStripePath = '/artist/stripe';

  /**
   * Join to stripe.
   *
   * how to create code look this https://stripe.com/docs/connect/standard-accounts
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `artistJoinToStripe()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artistJoinToStripe$Response(params: {
    /**
     * Get
     */
    body: JoinToStripeRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.ArtistJoinToStripePath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Join to stripe.
   *
   * how to create code look this https://stripe.com/docs/connect/standard-accounts
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `artistJoinToStripe$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  artistJoinToStripe(params: {
    /**
     * Get
     */
    body: JoinToStripeRequest;
  }): Observable<ResponseSuccess> {
    return this.artistJoinToStripe$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getArtistAccounts
   */
  static readonly GetArtistAccountsPath = '/artists';

  /**
   * search for artist profiles.
   *
   * DEPRECATED: use `GET /search/artist` instead.
   * search for artist profiles by name or mouseliveId
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArtistAccounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistAccounts$Response(params?: {
    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * search string
     */
    search?: string;
  }): Observable<StrictHttpResponse<GetArtistAccountsResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.GetArtistAccountsPath,
      'get',
    );
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
      rb.query('search', params.search, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetArtistAccountsResponseSuccess>;
        }),
      );
  }

  /**
   * search for artist profiles.
   *
   * DEPRECATED: use `GET /search/artist` instead.
   * search for artist profiles by name or mouseliveId
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getArtistAccounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistAccounts(params?: {
    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * search string
     */
    search?: string;
  }): Observable<GetArtistAccountsResponseSuccess> {
    return this.getArtistAccounts$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetArtistAccountsResponseSuccess>) =>
          r.body as GetArtistAccountsResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getArtistCalendar
   */
  static readonly GetArtistCalendarPath =
    '/artists/{artistAccountGUID}/calendar';

  /**
   * Retrieves artist calendar.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArtistCalendar()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistCalendar$Response(params: {
    /**
     * filter[from]
     */
    from?: string;

    /**
     * filter[to]
     */
    to?: string;

    /**
     * artist account ID
     */
    artistAccountGUID: string;
  }): Observable<StrictHttpResponse<GetArtistAccountCalendarResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.GetArtistCalendarPath,
      'get',
    );
    if (params) {
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
      rb.path('artistAccountGUID', params.artistAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetArtistAccountCalendarResponseSuccess>;
        }),
      );
  }

  /**
   * Retrieves artist calendar.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getArtistCalendar$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistCalendar(params: {
    /**
     * filter[from]
     */
    from?: string;

    /**
     * filter[to]
     */
    to?: string;

    /**
     * artist account ID
     */
    artistAccountGUID: string;
  }): Observable<GetArtistAccountCalendarResponseSuccess> {
    return this.getArtistCalendar$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetArtistAccountCalendarResponseSuccess>) =>
          r.body as GetArtistAccountCalendarResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getArtistFollowers
   */
  static readonly GetArtistFollowersPath =
    '/artists/{artistAccountGUID}/followers';

  /**
   * Retrieves list of followers(fans) for artist.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArtistFollowers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistFollowers$Response(params: {
    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * Artist Account ID
     */
    artistAccountGUID: string;
  }): Observable<StrictHttpResponse<GetArtistAccountFollowersResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.GetArtistFollowersPath,
      'get',
    );
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
      rb.path('artistAccountGUID', params.artistAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetArtistAccountFollowersResponseSuccess>;
        }),
      );
  }

  /**
   * Retrieves list of followers(fans) for artist.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getArtistFollowers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistFollowers(params: {
    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * Artist Account ID
     */
    artistAccountGUID: string;
  }): Observable<GetArtistAccountFollowersResponseSuccess> {
    return this.getArtistFollowers$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetArtistAccountFollowersResponseSuccess>) =>
          r.body as GetArtistAccountFollowersResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation statusArtistAccountId
   */
  static readonly StatusArtistAccountIdPath = '/artists/{id}/status';

  /**
   * returns artist account status.
   *
   * artist account status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statusArtistAccountId()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusArtistAccountId$Response(params: {
    /**
     * artist account ID
     */
    id: string;
  }): Observable<StrictHttpResponse<GetArtistMediaResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.StatusArtistAccountIdPath,
      'get',
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetArtistMediaResponse>;
        }),
      );
  }

  /**
   * returns artist account status.
   *
   * artist account status
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `statusArtistAccountId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusArtistAccountId(params: {
    /**
     * artist account ID
     */
    id: string;
  }): Observable<GetArtistMediaResponse> {
    return this.statusArtistAccountId$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetArtistMediaResponse>) =>
          r.body as GetArtistMediaResponse,
      ),
    );
  }

  /**
   * Path part for operation createArtistCalendarItem
   */
  static readonly CreateArtistCalendarItemPath = '/artists/calendar_items';

  /**
   * Add calendar item.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createArtistCalendarItem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createArtistCalendarItem$Response(params: {
    /**
     * Post
     */
    body: CreateCalendarItemRequest;
  }): Observable<
    StrictHttpResponse<CreateArtistAccountCalendarItemResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.CreateArtistCalendarItemPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreateArtistAccountCalendarItemResponseSuccess>;
        }),
      );
  }

  /**
   * Add calendar item.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createArtistCalendarItem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createArtistCalendarItem(params: {
    /**
     * Post
     */
    body: CreateCalendarItemRequest;
  }): Observable<CreateArtistAccountCalendarItemResponseSuccess> {
    return this.createArtistCalendarItem$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<CreateArtistAccountCalendarItemResponseSuccess>,
        ) => r.body as CreateArtistAccountCalendarItemResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation deleteArtistCalendarItem
   */
  static readonly DeleteArtistCalendarItemPath = '/artists/calendar_items/{id}';

  /**
   * Remove calendar item.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteArtistCalendarItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteArtistCalendarItem$Response(params: {
    /**
     * artist account ID
     */
    id: string;
  }): Observable<
    StrictHttpResponse<DeleteArtistAccountCalendarItemResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.DeleteArtistCalendarItemPath,
      'delete',
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<DeleteArtistAccountCalendarItemResponseSuccess>;
        }),
      );
  }

  /**
   * Remove calendar item.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteArtistCalendarItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteArtistCalendarItem(params: {
    /**
     * artist account ID
     */
    id: string;
  }): Observable<DeleteArtistAccountCalendarItemResponseSuccess> {
    return this.deleteArtistCalendarItem$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<DeleteArtistAccountCalendarItemResponseSuccess>,
        ) => r.body as DeleteArtistAccountCalendarItemResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation updateCalendarSettings
   */
  static readonly UpdateCalendarSettingsPath = '/artists/calendar_settings';

  /**
   * Updates calendar settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCalendarSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCalendarSettings$Response(params: {
    /**
     * Put
     */
    body: CreateOrUpdateCalendarSettingsRequest;
  }): Observable<
    StrictHttpResponse<UpdateArtistAccountCalendarSettingsResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.UpdateCalendarSettingsPath,
      'put',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UpdateArtistAccountCalendarSettingsResponseSuccess>;
        }),
      );
  }

  /**
   * Updates calendar settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCalendarSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCalendarSettings(params: {
    /**
     * Put
     */
    body: CreateOrUpdateCalendarSettingsRequest;
  }): Observable<UpdateArtistAccountCalendarSettingsResponseSuccess> {
    return this.updateCalendarSettings$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<UpdateArtistAccountCalendarSettingsResponseSuccess>,
        ) => r.body as UpdateArtistAccountCalendarSettingsResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation followArtist
   */
  static readonly FollowArtistPath =
    '/fan/follower/artist/artist/{artistAccountGUID}';

  /**
   * Follow up artist account(if authenticated as an artist).
   *
   * Current artist follows another artist account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `followArtist()` instead.
   *
   * This method doesn't expect any request body.
   */
  followArtist$Response(params: {
    /**
     * Artist Account ID
     */
    artistAccountGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.FollowArtistPath,
      'post',
    );
    if (params) {
      rb.path('artistAccountGUID', params.artistAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Follow up artist account(if authenticated as an artist).
   *
   * Current artist follows another artist account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `followArtist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  followArtist(params: {
    /**
     * Artist Account ID
     */
    artistAccountGUID: string;
  }): Observable<ResponseSuccess> {
    return this.followArtist$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation unfollowArtist
   */
  static readonly UnfollowArtistPath =
    '/fan/follower/artist/artist/{artistAccountGUID}';

  /**
   * Unfollow up artist account(if authenticated as an artist).
   *
   * Current artist unfollows another artist account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unfollowArtist()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowArtist$Response(params: {
    /**
     * Artist Account ID
     */
    artistAccountGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.UnfollowArtistPath,
      'delete',
    );
    if (params) {
      rb.path('artistAccountGUID', params.artistAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Unfollow up artist account(if authenticated as an artist).
   *
   * Current artist unfollows another artist account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unfollowArtist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowArtist(params: {
    /**
     * Artist Account ID
     */
    artistAccountGUID: string;
  }): Observable<ResponseSuccess> {
    return this.unfollowArtist$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation followArtistFan
   */
  static readonly FollowArtistFanPath =
    '/fan/follower/artist/fan/{fanAccountGUID}';

  /**
   * Follow up fan account(if authenticated as an artist).
   *
   * Current artist follows the fan account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `followArtistFan()` instead.
   *
   * This method doesn't expect any request body.
   */
  followArtistFan$Response(params: {
    /**
     * Fan Account ID
     */
    fanAccountGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.FollowArtistFanPath,
      'post',
    );
    if (params) {
      rb.path('fanAccountGUID', params.fanAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Follow up fan account(if authenticated as an artist).
   *
   * Current artist follows the fan account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `followArtistFan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  followArtistFan(params: {
    /**
     * Fan Account ID
     */
    fanAccountGUID: string;
  }): Observable<ResponseSuccess> {
    return this.followArtistFan$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation unfollowArtistFan
   */
  static readonly UnfollowArtistFanPath =
    '/fan/follower/artist/fan/{fanAccountGUID}';

  /**
   * Unfollow up fan account(if authenticated as an artist).
   *
   * Current artist unfollows the fan account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unfollowArtistFan()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowArtistFan$Response(params: {
    /**
     * Fan Account ID
     */
    fanAccountGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.UnfollowArtistFanPath,
      'delete',
    );
    if (params) {
      rb.path('fanAccountGUID', params.fanAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Unfollow up fan account(if authenticated as an artist).
   *
   * Current artist unfollows the fan account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unfollowArtistFan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowArtistFan(params: {
    /**
     * Fan Account ID
     */
    fanAccountGUID: string;
  }): Observable<ResponseSuccess> {
    return this.unfollowArtistFan$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation followArtistOrganizer
   */
  static readonly FollowArtistOrganizerPath =
    '/fan/follower/artist/organizer/{organizerAccountGUID}';

  /**
   * Follow up Organizer Account(if authenticated as an artist).
   *
   * Current artist follows the Organizer Account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `followArtistOrganizer()` instead.
   *
   * This method doesn't expect any request body.
   */
  followArtistOrganizer$Response(params: {
    /**
     * Organizer Account ID
     */
    organizerAccountGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.FollowArtistOrganizerPath,
      'post',
    );
    if (params) {
      rb.path('organizerAccountGUID', params.organizerAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Follow up Organizer Account(if authenticated as an artist).
   *
   * Current artist follows the Organizer Account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `followArtistOrganizer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  followArtistOrganizer(params: {
    /**
     * Organizer Account ID
     */
    organizerAccountGUID: string;
  }): Observable<ResponseSuccess> {
    return this.followArtistOrganizer$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation unfollowArtistOrganizer
   */
  static readonly UnfollowArtistOrganizerPath =
    '/fan/follower/artist/organizer/{organizerAccountGUID}';

  /**
   * Unfollow up Organizer Account(if authenticated as an artist).
   *
   * Current artist unfollows the Organizer Account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unfollowArtistOrganizer()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowArtistOrganizer$Response(params: {
    /**
     * Organizer Account ID
     */
    organizerAccountGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.UnfollowArtistOrganizerPath,
      'delete',
    );
    if (params) {
      rb.path('organizerAccountGUID', params.organizerAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Unfollow up Organizer Account(if authenticated as an artist).
   *
   * Current artist unfollows the Organizer Account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unfollowArtistOrganizer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowArtistOrganizer(params: {
    /**
     * Organizer Account ID
     */
    organizerAccountGUID: string;
  }): Observable<ResponseSuccess> {
    return this.unfollowArtistOrganizer$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation followVenue
   */
  static readonly FollowVenuePath =
    '/fan/follower/artist/venue/{venueAccountGUID}';

  /**
   * Follow up venue account(if authenticated as an artist).
   *
   * Current artist follows the venue account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `followVenue()` instead.
   *
   * This method doesn't expect any request body.
   */
  followVenue$Response(params: {
    /**
     * Venue Account ID
     */
    venueAccountGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.FollowVenuePath,
      'post',
    );
    if (params) {
      rb.path('venueAccountGUID', params.venueAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Follow up venue account(if authenticated as an artist).
   *
   * Current artist follows the venue account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `followVenue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  followVenue(params: {
    /**
     * Venue Account ID
     */
    venueAccountGUID: string;
  }): Observable<ResponseSuccess> {
    return this.followVenue$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation unfollowVenueArtist
   */
  static readonly UnfollowVenueArtistPath =
    '/fan/follower/artist/venue/{venueAccountGUID}';

  /**
   * Unfollow venue account(if authenticated as an artist).
   *
   * Current artist unfollows the venue account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unfollowVenueArtist()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowVenueArtist$Response(params: {
    /**
     * Venue Account ID
     */
    venueAccountGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.UnfollowVenueArtistPath,
      'delete',
    );
    if (params) {
      rb.path('venueAccountGUID', params.venueAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Unfollow venue account(if authenticated as an artist).
   *
   * Current artist unfollows the venue account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unfollowVenueArtist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unfollowVenueArtist(params: {
    /**
     * Venue Account ID
     */
    venueAccountGUID: string;
  }): Observable<ResponseSuccess> {
    return this.unfollowVenueArtist$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getArtistFollowersCount
   */
  static readonly GetArtistFollowersCountPath =
    '/fans/{userGUID}/artist/followers_count';

  /**
   * Followers count.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArtistFollowersCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistFollowersCount$Response(params: {
    /**
     * Fan ID
     */
    userGUID: string;
  }): Observable<StrictHttpResponse<FollowersCount>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.GetArtistFollowersCountPath,
      'get',
    );
    if (params) {
      rb.path('userGUID', params.userGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FollowersCount>;
        }),
      );
  }

  /**
   * Followers count.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getArtistFollowersCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistFollowersCount(params: {
    /**
     * Fan ID
     */
    userGUID: string;
  }): Observable<FollowersCount> {
    return this.getArtistFollowersCount$Response(params).pipe(
      map((r: StrictHttpResponse<FollowersCount>) => r.body as FollowersCount),
    );
  }

  /**
   * Path part for operation getArtistFollowingCount
   */
  static readonly GetArtistFollowingCountPath =
    '/fans/{userGUID}/artist/following_count';

  /**
   * Following count.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArtistFollowingCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistFollowingCount$Response(params: {
    /**
     * Fan ID
     */
    userGUID: string;
  }): Observable<StrictHttpResponse<FollowingCount>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ArtistAccountService.GetArtistFollowingCountPath,
      'get',
    );
    if (params) {
      rb.path('userGUID', params.userGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FollowingCount>;
        }),
      );
  }

  /**
   * Following count.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getArtistFollowingCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistFollowingCount(params: {
    /**
     * Fan ID
     */
    userGUID: string;
  }): Observable<FollowingCount> {
    return this.getArtistFollowingCount$Response(params).pipe(
      map((r: StrictHttpResponse<FollowingCount>) => r.body as FollowingCount),
    );
  }
}
