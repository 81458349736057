/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetFanMediaResponse } from '../models/get-fan-media-response';

@Injectable({
  providedIn: 'root',
})
export class EventsByFanIdService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation upcomingEventsFanId
   */
  static readonly UpcomingEventsFanIdPath = '/fans/{id}/media';

  /**
   * returns all events for fan by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upcomingEventsFanId()` instead.
   *
   * This method doesn't expect any request body.
   */
  upcomingEventsFanId$Response(params: {
    /**
     * Fan Account ID
     */
    id: string;
  }): Observable<StrictHttpResponse<GetFanMediaResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsByFanIdService.UpcomingEventsFanIdPath,
      'get',
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetFanMediaResponse>;
        }),
      );
  }

  /**
   * returns all events for fan by id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `upcomingEventsFanId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  upcomingEventsFanId(params: {
    /**
     * Fan Account ID
     */
    id: string;
  }): Observable<GetFanMediaResponse> {
    return this.upcomingEventsFanId$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetFanMediaResponse>) =>
          r.body as GetFanMediaResponse,
      ),
    );
  }
}
