/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuthResponseSuccess } from '../models/auth-response-success';
import { ConfirmRequest } from '../models/confirm-request';
import { CreatePaymentMethodResponseSuccess } from '../models/create-payment-method-response-success';
import { CreateUserRequest } from '../models/create-user-request';
import { GetApplicationSettingsResponseSuccess } from '../models/get-application-settings-response-success';
import { GetNotificationsSettingsResponseSuccess } from '../models/get-notifications-settings-response-success';
import { GetPaymentMethodResponseSuccess } from '../models/get-payment-method-response-success';
import { GetPaymentMethodsResponseSuccess } from '../models/get-payment-methods-response-success';
import { GetUserInfoResponseSuccess } from '../models/get-user-info-response-success';
import { NotificationsSettings } from '../models/notifications-settings';
import { RegistrationResponseSuccess } from '../models/registration-response-success';
import { ResetPasswordRequest } from '../models/reset-password-request';
import { ResponseSuccess } from '../models/response-success';
import { SaveCardToCustomerRequest } from '../models/save-card-to-customer-request';
import { SetApplicationSettingsRequest } from '../models/set-application-settings-request';
import { UpdatePasswordRequest } from '../models/update-password-request';
import { UpdateUserRequest } from '../models/update-user-request';
import { UserFollowByAccountTypesResponse } from '../models/user-follow-by-account-types-response';
import { Login } from '../models/login';
import { LoginOAuth } from '../models/login-o-auth';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation checkProfileFollowingOther
   */
  static readonly CheckProfileFollowingOtherPath =
    '/profiles/{followerID}/following/{followedID}';

  /**
   * check if profile following other profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkProfileFollowingOther()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkProfileFollowingOther$Response(params: {
    /**
     * Follower ID (Example: ID of follower)
     */
    followerID: string;

    /**
     * Followed ID (Example: ID of followed)
     */
    followedID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.CheckProfileFollowingOtherPath,
      'get',
    );
    if (params) {
      rb.path('followerID', params.followerID, {});
      rb.path('followedID', params.followedID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * check if profile following other profile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkProfileFollowingOther$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkProfileFollowingOther(params: {
    /**
     * Follower ID (Example: ID of follower)
     */
    followerID: string;

    /**
     * Followed ID (Example: ID of followed)
     */
    followedID: string;
  }): Observable<ResponseSuccess> {
    return this.checkProfileFollowingOther$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getUserInfo
   */
  static readonly GetUserInfoPath = '/user';

  /**
   * Get user info.
   *
   * Get user info
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserInfo$Response(params?: {}): Observable<
    StrictHttpResponse<GetUserInfoResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.GetUserInfoPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetUserInfoResponseSuccess>;
        }),
      );
  }

  /**
   * Get user info.
   *
   * Get user info
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserInfo(params?: {}): Observable<GetUserInfoResponseSuccess> {
    return this.getUserInfo$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetUserInfoResponseSuccess>) =>
          r.body as GetUserInfoResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation updateUser
   */
  static readonly UpdateUserPath = '/user';

  /**
   * Update user.
   *
   * Update user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser$Response(params: {
    /**
     * Get
     */
    body: UpdateUserRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.UpdateUserPath,
      'put',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Update user.
   *
   * Update user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser(params: {
    /**
     * Get
     */
    body: UpdateUserRequest;
  }): Observable<ResponseSuccess> {
    return this.updateUser$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation createUser
   */
  static readonly CreateUserPath = '/user';

  /**
   * Create user.
   *
   * Create user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser$Response(params: {
    /**
     * Get
     */
    body: CreateUserRequest;
  }): Observable<StrictHttpResponse<RegistrationResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.CreateUserPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RegistrationResponseSuccess>;
        }),
      );
  }

  /**
   * Create user.
   *
   * Create user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser(params: {
    /**
     * Get
     */
    body: CreateUserRequest;
  }): Observable<RegistrationResponseSuccess> {
    return this.createUser$Response(params).pipe(
      map(
        (r: StrictHttpResponse<RegistrationResponseSuccess>) =>
          r.body as RegistrationResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation removeUser
   */
  static readonly RemoveUserPath = '/user';

  /**
   * Remove user.
   *
   * Remove user and remove add relations(fan account, artist account, venue account, Organizer Account)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeUser$Response(params?: {}): Observable<
    StrictHttpResponse<ResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.RemoveUserPath,
      'delete',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Remove user.
   *
   * Remove user and remove add relations(fan account, artist account, venue account, Organizer Account)
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeUser(params?: {}): Observable<ResponseSuccess> {
    return this.removeUser$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getUserApplicationSettings
   */
  static readonly GetUserApplicationSettingsPath = '/user/application_settings';

  /**
   * Get application settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserApplicationSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserApplicationSettings$Response(params?: {}): Observable<
    StrictHttpResponse<GetApplicationSettingsResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.GetUserApplicationSettingsPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetApplicationSettingsResponseSuccess>;
        }),
      );
  }

  /**
   * Get application settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserApplicationSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserApplicationSettings(params?: {}): Observable<GetApplicationSettingsResponseSuccess> {
    return this.getUserApplicationSettings$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetApplicationSettingsResponseSuccess>) =>
          r.body as GetApplicationSettingsResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation setUserApplicationSettings
   */
  static readonly SetUserApplicationSettingsPath = '/user/application_settings';

  /**
   * Set user application settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setUserApplicationSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setUserApplicationSettings$Response(params: {
    /**
     * JSON
     */
    body: SetApplicationSettingsRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.SetUserApplicationSettingsPath,
      'put',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Set user application settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setUserApplicationSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setUserApplicationSettings(params: {
    /**
     * JSON
     */
    body: SetApplicationSettingsRequest;
  }): Observable<ResponseSuccess> {
    return this.setUserApplicationSettings$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation confirmCode
   */
  static readonly ConfirmCodePath = '/user/confirmation';

  /**
   * Confirm code.
   *
   * Confirm code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirmCode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmCode$Response(params: {
    /**
     * Get
     */
    body: ConfirmRequest;
  }): Observable<StrictHttpResponse<RegistrationResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.ConfirmCodePath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RegistrationResponseSuccess>;
        }),
      );
  }

  /**
   * Confirm code.
   *
   * Confirm code
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `confirmCode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmCode(params: {
    /**
     * Get
     */
    body: ConfirmRequest;
  }): Observable<RegistrationResponseSuccess> {
    return this.confirmCode$Response(params).pipe(
      map(
        (r: StrictHttpResponse<RegistrationResponseSuccess>) =>
          r.body as RegistrationResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getUserFollowers
   */
  static readonly GetUserFollowersPath = '/user/followers';

  /**
   * Get user followers.
   *
   * Get user followers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserFollowers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserFollowers$Response(params?: {}): Observable<
    StrictHttpResponse<UserFollowByAccountTypesResponse>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.GetUserFollowersPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserFollowByAccountTypesResponse>;
        }),
      );
  }

  /**
   * Get user followers.
   *
   * Get user followers
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserFollowers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserFollowers(params?: {}): Observable<UserFollowByAccountTypesResponse> {
    return this.getUserFollowers$Response(params).pipe(
      map(
        (r: StrictHttpResponse<UserFollowByAccountTypesResponse>) =>
          r.body as UserFollowByAccountTypesResponse,
      ),
    );
  }

  /**
   * Path part for operation getUserFollowing
   */
  static readonly GetUserFollowingPath = '/user/following';

  /**
   * Get user following.
   *
   * Get user following
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserFollowing()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserFollowing$Response(params?: {}): Observable<
    StrictHttpResponse<UserFollowByAccountTypesResponse>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.GetUserFollowingPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserFollowByAccountTypesResponse>;
        }),
      );
  }

  /**
   * Get user following.
   *
   * Get user following
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserFollowing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserFollowing(params?: {}): Observable<UserFollowByAccountTypesResponse> {
    return this.getUserFollowing$Response(params).pipe(
      map(
        (r: StrictHttpResponse<UserFollowByAccountTypesResponse>) =>
          r.body as UserFollowByAccountTypesResponse,
      ),
    );
  }

  /**
   * Path part for operation loginUser
   */
  static readonly LoginUserPath = '/user/login';

  /**
   * Login with one time password.
   *
   * Checks if otp is correct. Returns JWT with access to all end-points
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loginUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loginUser$Response(params: {
    /**
     * Get
     */
    body: Login;
  }): Observable<StrictHttpResponse<AuthResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.LoginUserPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AuthResponseSuccess>;
        }),
      );
  }

  /**
   * Login with one time password.
   *
   * Checks if otp is correct. Returns JWT with access to all end-points
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loginUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loginUser(params: {
    /**
     * Get
     */
    body: Login;
  }): Observable<AuthResponseSuccess> {
    return this.loginUser$Response(params).pipe(
      map(
        (r: StrictHttpResponse<AuthResponseSuccess>) =>
          r.body as AuthResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation loginFacebook
   */
  static readonly LoginFacebookPath = '/user/login/facebook';

  /**
   * Login with facebook account information.
   *
   * Method receives the access token from facebook. In addition, it will set the state of the user. In case if this is the first time user will be in 'inactive' state till the moment when mouselive login and password will be provided
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loginFacebook()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loginFacebook$Response(params: {
    /**
     * Get
     */
    body: LoginOAuth;
  }): Observable<StrictHttpResponse<AuthResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.LoginFacebookPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AuthResponseSuccess>;
        }),
      );
  }

  /**
   * Login with facebook account information.
   *
   * Method receives the access token from facebook. In addition, it will set the state of the user. In case if this is the first time user will be in 'inactive' state till the moment when mouselive login and password will be provided
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loginFacebook$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loginFacebook(params: {
    /**
     * Get
     */
    body: LoginOAuth;
  }): Observable<AuthResponseSuccess> {
    return this.loginFacebook$Response(params).pipe(
      map(
        (r: StrictHttpResponse<AuthResponseSuccess>) =>
          r.body as AuthResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation loginGoogle
   */
  static readonly LoginGooglePath = '/user/login/google';

  /**
   * Login with google account information.
   *
   * Method receives the access token from google. In addition, it will set the state of the user. In case if this is the first time user will be in 'inactive' state till the moment when mouselive login and password will be provided
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loginGoogle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loginGoogle$Response(params: {
    /**
     * Get
     */
    body: LoginOAuth;
  }): Observable<StrictHttpResponse<AuthResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.LoginGooglePath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AuthResponseSuccess>;
        }),
      );
  }

  /**
   * Login with google account information.
   *
   * Method receives the access token from google. In addition, it will set the state of the user. In case if this is the first time user will be in 'inactive' state till the moment when mouselive login and password will be provided
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `loginGoogle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loginGoogle(params: {
    /**
     * Get
     */
    body: LoginOAuth;
  }): Observable<AuthResponseSuccess> {
    return this.loginGoogle$Response(params).pipe(
      map(
        (r: StrictHttpResponse<AuthResponseSuccess>) =>
          r.body as AuthResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation logout
   */
  static readonly LogoutPath = '/user/logout';

  /**
   * Logs user out.
   *
   * Blocks current JWT
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logout()` instead.
   *
   * This method doesn't expect any request body.
   */
  logout$Response(params?: {}): Observable<
    StrictHttpResponse<ResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.LogoutPath,
      'delete',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Logs user out.
   *
   * Blocks current JWT
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `logout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  logout(params?: {}): Observable<ResponseSuccess> {
    return this.logout$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getUserNotificationsSettings
   */
  static readonly GetUserNotificationsSettingsPath =
    '/user/notifications_settings';

  /**
   * Get notifications settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserNotificationsSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserNotificationsSettings$Response(params?: {}): Observable<
    StrictHttpResponse<GetNotificationsSettingsResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.GetUserNotificationsSettingsPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetNotificationsSettingsResponseSuccess>;
        }),
      );
  }

  /**
   * Get notifications settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserNotificationsSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserNotificationsSettings(params?: {}): Observable<GetNotificationsSettingsResponseSuccess> {
    return this.getUserNotificationsSettings$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetNotificationsSettingsResponseSuccess>) =>
          r.body as GetNotificationsSettingsResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation setUserNotificationsSettings
   */
  static readonly SetUserNotificationsSettingsPath =
    '/user/notifications_settings';

  /**
   * Set user notifications settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setUserNotificationsSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setUserNotificationsSettings$Response(params: {
    /**
     * JSON
     */
    body: NotificationsSettings;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.SetUserNotificationsSettingsPath,
      'put',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Set user notifications settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setUserNotificationsSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setUserNotificationsSettings(params: {
    /**
     * JSON
     */
    body: NotificationsSettings;
  }): Observable<ResponseSuccess> {
    return this.setUserNotificationsSettings$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation updatePassword
   */
  static readonly UpdatePasswordPath = '/user/password';

  /**
   * Update password.
   *
   * Update user password
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePassword$Response(params: {
    /**
     * Get
     */
    body: UpdatePasswordRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.UpdatePasswordPath,
      'put',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Update password.
   *
   * Update user password
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePassword(params: {
    /**
     * Get
     */
    body: UpdatePasswordRequest;
  }): Observable<ResponseSuccess> {
    return this.updatePassword$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getPaymentMethods
   */
  static readonly GetPaymentMethodsPath = '/user/payment_methods';

  /**
   * retrieve payment methods.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentMethods()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentMethods$Response(params?: {}): Observable<
    StrictHttpResponse<GetPaymentMethodsResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.GetPaymentMethodsPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetPaymentMethodsResponseSuccess>;
        }),
      );
  }

  /**
   * retrieve payment methods.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPaymentMethods$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentMethods(params?: {}): Observable<GetPaymentMethodsResponseSuccess> {
    return this.getPaymentMethods$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetPaymentMethodsResponseSuccess>) =>
          r.body as GetPaymentMethodsResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation saveCardToCustomer
   */
  static readonly SaveCardToCustomerPath = '/user/payment_methods';

  /**
   * Save card to customer.
   *
   * Save card to customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveCardToCustomer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveCardToCustomer$Response(params: {
    /**
     * Get
     */
    body: SaveCardToCustomerRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.SaveCardToCustomerPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Save card to customer.
   *
   * Save card to customer
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveCardToCustomer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveCardToCustomer(params: {
    /**
     * Get
     */
    body: SaveCardToCustomerRequest;
  }): Observable<ResponseSuccess> {
    return this.saveCardToCustomer$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getPaymentMethod
   */
  static readonly GetPaymentMethodPath =
    '/user/payment_methods/{stripePaymentMethodID}';

  /**
   * retrieve payment method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPaymentMethod()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentMethod$Response(params: {
    /**
     * Stripe Payment Method
     */
    stripePaymentMethodID: string;
  }): Observable<StrictHttpResponse<GetPaymentMethodResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.GetPaymentMethodPath,
      'get',
    );
    if (params) {
      rb.path('stripePaymentMethodID', params.stripePaymentMethodID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetPaymentMethodResponseSuccess>;
        }),
      );
  }

  /**
   * retrieve payment method.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPaymentMethod$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPaymentMethod(params: {
    /**
     * Stripe Payment Method
     */
    stripePaymentMethodID: string;
  }): Observable<GetPaymentMethodResponseSuccess> {
    return this.getPaymentMethod$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetPaymentMethodResponseSuccess>) =>
          r.body as GetPaymentMethodResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation verifyPaymentMethod
   */
  static readonly VerifyPaymentMethodPath =
    '/user/payment_methods/{stripePaymentMethodID}/verify';

  /**
   * verify payment method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `verifyPaymentMethod()` instead.
   *
   * This method doesn't expect any request body.
   */
  verifyPaymentMethod$Response(params: {
    /**
     * Payment Method ID (Example: ID of payment method)
     */
    stripePaymentMethodID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.VerifyPaymentMethodPath,
      'put',
    );
    if (params) {
      rb.path('stripePaymentMethodID', params.stripePaymentMethodID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * verify payment method.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `verifyPaymentMethod$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  verifyPaymentMethod(params: {
    /**
     * Payment Method ID (Example: ID of payment method)
     */
    stripePaymentMethodID: string;
  }): Observable<ResponseSuccess> {
    return this.verifyPaymentMethod$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation refreshResponse
   */
  static readonly RefreshResponsePath = '/user/refreshToken';

  /**
   * Refresh jwt token.
   *
   * Refresh jwt token if time is over
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `refreshResponse()` instead.
   *
   * This method doesn't expect any request body.
   */
  refreshResponse$Response(params?: {}): Observable<
    StrictHttpResponse<RegistrationResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.RefreshResponsePath,
      'post',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<RegistrationResponseSuccess>;
        }),
      );
  }

  /**
   * Refresh jwt token.
   *
   * Refresh jwt token if time is over
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `refreshResponse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  refreshResponse(params?: {}): Observable<RegistrationResponseSuccess> {
    return this.refreshResponse$Response(params).pipe(
      map(
        (r: StrictHttpResponse<RegistrationResponseSuccess>) =>
          r.body as RegistrationResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation resetPassword
   */
  static readonly ResetPasswordPath = '/user/reset_password';

  /**
   * Reset password.
   *
   * Reset password
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetPassword$Response(params: {
    /**
     * Get
     */
    body: ResetPasswordRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.ResetPasswordPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Reset password.
   *
   * Reset password
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resetPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetPassword(params: {
    /**
     * Get
     */
    body: ResetPasswordRequest;
  }): Observable<ResponseSuccess> {
    return this.resetPassword$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation createSetupIntent
   */
  static readonly CreateSetupIntentPath = '/user/setup_intent';

  /**
   * Create setup intent.
   *
   * docs https://stripe.com/docs/payments/save-and-reuse
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSetupIntent()` instead.
   *
   * This method doesn't expect any request body.
   */
  createSetupIntent$Response(params?: {}): Observable<
    StrictHttpResponse<CreatePaymentMethodResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.CreateSetupIntentPath,
      'post',
    );
    if (params) {
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePaymentMethodResponseSuccess>;
        }),
      );
  }

  /**
   * Create setup intent.
   *
   * docs https://stripe.com/docs/payments/save-and-reuse
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createSetupIntent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createSetupIntent(params?: {}): Observable<CreatePaymentMethodResponseSuccess> {
    return this.createSetupIntent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<CreatePaymentMethodResponseSuccess>) =>
          r.body as CreatePaymentMethodResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation confirmSetupIntent
   */
  static readonly ConfirmSetupIntentPath =
    '/user/setup_intent/{setupIntentID}/confirm';

  /**
   * Confirm setup intent.
   *
   * Confirm setup intent after confirm in stripe
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirmSetupIntent()` instead.
   *
   * This method doesn't expect any request body.
   */
  confirmSetupIntent$Response(params: {
    /**
     * Setup Intent ID
     */
    setupIntentID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.ConfirmSetupIntentPath,
      'put',
    );
    if (params) {
      rb.path('setupIntentID', params.setupIntentID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Confirm setup intent.
   *
   * Confirm setup intent after confirm in stripe
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `confirmSetupIntent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  confirmSetupIntent(params: {
    /**
     * Setup Intent ID
     */
    setupIntentID: string;
  }): Observable<ResponseSuccess> {
    return this.confirmSetupIntent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getUserInvite
   */
  static readonly GetUserInvitePath = '/users/invites/{userInviteGUID}';

  /**
   * Get user invite.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserInvite()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserInvite$Response(params: {
    /**
     * Invite ID
     */
    userInviteGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserService.GetUserInvitePath,
      'get',
    );
    if (params) {
      rb.path('userInviteGUID', params.userInviteGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Get user invite.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserInvite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserInvite(params: {
    /**
     * Invite ID
     */
    userInviteGUID: string;
  }): Observable<ResponseSuccess> {
    return this.getUserInvite$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }
}
