/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddOrUpdateArtistsToEvent } from '../models/add-or-update-artists-to-event';
import { ArtistPrivateInfoResponse } from '../models/artist-private-info-response';
import { DeclineArtistRequestOfEvent } from '../models/decline-artist-request-of-event';
import { EventArtistResponse } from '../models/event-artist-response';
import { EventArtistsResponse } from '../models/event-artists-response';
import { RequestArtistOfEvent } from '../models/request-artist-of-event';
import { ResendArtistRequestOfEvent } from '../models/resend-artist-request-of-event';
import { ResponseSuccess } from '../models/response-success';
import { UpdateRequestArtistOfEvent } from '../models/update-request-artist-of-event';

@Injectable({
  providedIn: 'root',
})
export class EventsArtistService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getArtistsOfEvent
   */
  static readonly GetArtistsOfEventPath = '/events/{eventGUID}/artists';

  /**
   * Get artists to event.
   *
   * Get artists to event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArtistsOfEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistsOfEvent$Response(params: {
    /**
     * eventGUID
     */
    eventGUID: string;
  }): Observable<StrictHttpResponse<EventArtistsResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsArtistService.GetArtistsOfEventPath,
      'get',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventArtistsResponse>;
        }),
      );
  }

  /**
   * Get artists to event.
   *
   * Get artists to event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getArtistsOfEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistsOfEvent(params: {
    /**
     * eventGUID
     */
    eventGUID: string;
  }): Observable<EventArtistsResponse> {
    return this.getArtistsOfEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<EventArtistsResponse>) =>
          r.body as EventArtistsResponse,
      ),
    );
  }

  /**
   * Path part for operation addArtistsOfEvent
   */
  static readonly AddArtistsOfEventPath = '/events/{eventGUID}/artists';

  /**
   * Add artists to event.
   *
   * Add artists to event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addArtistsOfEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addArtistsOfEvent$Response(params: {
    /**
     * eventGUID
     */
    eventGUID: string;

    /**
     * Struct
     */
    body: AddOrUpdateArtistsToEvent;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsArtistService.AddArtistsOfEventPath,
      'post',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Add artists to event.
   *
   * Add artists to event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addArtistsOfEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addArtistsOfEvent(params: {
    /**
     * eventGUID
     */
    eventGUID: string;

    /**
     * Struct
     */
    body: AddOrUpdateArtistsToEvent;
  }): Observable<ResponseSuccess> {
    return this.addArtistsOfEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getArtistPrivateInfoOfEvent
   */
  static readonly GetArtistPrivateInfoOfEventPath =
    '/events/{eventGUID}/artists/{artistGUID}';

  /**
   * Get artist private info of event.
   *
   * Get artist private info of event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArtistPrivateInfoOfEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistPrivateInfoOfEvent$Response(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Artist ID (Example: ID of artist profile)
     */
    artistGUID: string;
  }): Observable<StrictHttpResponse<ArtistPrivateInfoResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsArtistService.GetArtistPrivateInfoOfEventPath,
      'get',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.path('artistGUID', params.artistGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ArtistPrivateInfoResponse>;
        }),
      );
  }

  /**
   * Get artist private info of event.
   *
   * Get artist private info of event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getArtistPrivateInfoOfEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistPrivateInfoOfEvent(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Artist ID (Example: ID of artist profile)
     */
    artistGUID: string;
  }): Observable<ArtistPrivateInfoResponse> {
    return this.getArtistPrivateInfoOfEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ArtistPrivateInfoResponse>) =>
          r.body as ArtistPrivateInfoResponse,
      ),
    );
  }

  /**
   * Path part for operation removeArtistFromEvent
   */
  static readonly RemoveArtistFromEventPath =
    '/events/{eventGUID}/artists/{artistGUID}';

  /**
   * Remove artist from event.
   *
   * Remove artist from event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeArtistFromEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeArtistFromEvent$Response(params: {
    /**
     * eventGUID
     */
    eventGUID: string;

    /**
     * artistGUID
     */
    artistGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsArtistService.RemoveArtistFromEventPath,
      'delete',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.path('artistGUID', params.artistGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Remove artist from event.
   *
   * Remove artist from event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeArtistFromEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeArtistFromEvent(params: {
    /**
     * eventGUID
     */
    eventGUID: string;

    /**
     * artistGUID
     */
    artistGUID: string;
  }): Observable<ResponseSuccess> {
    return this.removeArtistFromEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation acceptArtistRequestOfEvent
   */
  static readonly AcceptArtistRequestOfEventPath =
    '/events/{eventGUID}/artists/{artistGUID}/accept';

  /**
   * Accept artist request to event.
   *
   * Accept artist request to event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acceptArtistRequestOfEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptArtistRequestOfEvent$Response(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Artist ID (Example: ID of artist profile)
     */
    artistGUID: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsArtistService.AcceptArtistRequestOfEventPath,
      'put',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.path('artistGUID', params.artistGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Accept artist request to event.
   *
   * Accept artist request to event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `acceptArtistRequestOfEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptArtistRequestOfEvent(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Artist ID (Example: ID of artist profile)
     */
    artistGUID: string;
  }): Observable<ResponseSuccess> {
    return this.acceptArtistRequestOfEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation declineArtistRequestOfEvent
   */
  static readonly DeclineArtistRequestOfEventPath =
    '/events/{eventGUID}/artists/{artistGUID}/decline';

  /**
   * Decline artist request to event.
   *
   * Decline artist request to event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `declineArtistRequestOfEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  declineArtistRequestOfEvent$Response(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Artist ID (Example: ID of artist profile)
     */
    artistGUID: string;

    /**
     * Struct
     */
    body: DeclineArtistRequestOfEvent;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsArtistService.DeclineArtistRequestOfEventPath,
      'put',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.path('artistGUID', params.artistGUID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Decline artist request to event.
   *
   * Decline artist request to event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `declineArtistRequestOfEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  declineArtistRequestOfEvent(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Artist ID (Example: ID of artist profile)
     */
    artistGUID: string;

    /**
     * Struct
     */
    body: DeclineArtistRequestOfEvent;
  }): Observable<ResponseSuccess> {
    return this.declineArtistRequestOfEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getArtistsRequestOfEvent
   */
  static readonly GetArtistsRequestOfEventPath =
    '/events/{eventGUID}/artists/{artistGUID}/request';

  /**
   * Get artists request to event.
   *
   * Get artists request to event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArtistsRequestOfEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistsRequestOfEvent$Response(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Artist ID (Example: ID of artist profile)
     */
    artistGUID: string;
  }): Observable<StrictHttpResponse<EventArtistResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsArtistService.GetArtistsRequestOfEventPath,
      'get',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.path('artistGUID', params.artistGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventArtistResponse>;
        }),
      );
  }

  /**
   * Get artists request to event.
   *
   * Get artists request to event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getArtistsRequestOfEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtistsRequestOfEvent(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Artist ID (Example: ID of artist profile)
     */
    artistGUID: string;
  }): Observable<EventArtistResponse> {
    return this.getArtistsRequestOfEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<EventArtistResponse>) =>
          r.body as EventArtistResponse,
      ),
    );
  }

  /**
   * Path part for operation confirmUpdatesFromArtistToRequestOfEvent
   */
  static readonly ConfirmUpdatesFromArtistToRequestOfEventPath =
    '/events/{eventGUID}/artists/{artistGUID}/request';

  /**
   * Confirm and update request from artist of event.
   *
   * Confirm and update request from artist of event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirmUpdatesFromArtistToRequestOfEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmUpdatesFromArtistToRequestOfEvent$Response(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Artist ID (Example: ID of artist profile)
     */
    artistGUID: string;

    /**
     * In
     */
    body: UpdateRequestArtistOfEvent;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsArtistService.ConfirmUpdatesFromArtistToRequestOfEventPath,
      'put',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.path('artistGUID', params.artistGUID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Confirm and update request from artist of event.
   *
   * Confirm and update request from artist of event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `confirmUpdatesFromArtistToRequestOfEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmUpdatesFromArtistToRequestOfEvent(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Artist ID (Example: ID of artist profile)
     */
    artistGUID: string;

    /**
     * In
     */
    body: UpdateRequestArtistOfEvent;
  }): Observable<ResponseSuccess> {
    return this.confirmUpdatesFromArtistToRequestOfEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation sendRequestToArtistOfEvent
   */
  static readonly SendRequestToArtistOfEventPath =
    '/events/{eventGUID}/artists/{artistGUID}/request';

  /**
   * Send request to artist of event.
   *
   * Send request to artist of event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendRequestToArtistOfEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendRequestToArtistOfEvent$Response(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Artist ID (Example: ID of artist profile)
     */
    artistGUID: string;

    /**
     * In
     */
    body: RequestArtistOfEvent;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsArtistService.SendRequestToArtistOfEventPath,
      'post',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.path('artistGUID', params.artistGUID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Send request to artist of event.
   *
   * Send request to artist of event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendRequestToArtistOfEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendRequestToArtistOfEvent(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Artist ID (Example: ID of artist profile)
     */
    artistGUID: string;

    /**
     * In
     */
    body: RequestArtistOfEvent;
  }): Observable<ResponseSuccess> {
    return this.sendRequestToArtistOfEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation resendRequestToArtistOfEvent
   */
  static readonly ResendRequestToArtistOfEventPath =
    '/events/{eventGUID}/artists/{artistGUID}/resend';

  /**
   * Resend request to artist of event.
   *
   * Resend request to artist of event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resendRequestToArtistOfEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resendRequestToArtistOfEvent$Response(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Artist ID (Example: ID of artist profile)
     */
    artistGUID: string;

    /**
     * In
     */
    body: ResendArtistRequestOfEvent;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsArtistService.ResendRequestToArtistOfEventPath,
      'post',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.path('artistGUID', params.artistGUID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Resend request to artist of event.
   *
   * Resend request to artist of event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resendRequestToArtistOfEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resendRequestToArtistOfEvent(params: {
    /**
     * Event ID (Example: some ID of events)
     */
    eventGUID: string;

    /**
     * Artist ID (Example: ID of artist profile)
     */
    artistGUID: string;

    /**
     * In
     */
    body: ResendArtistRequestOfEvent;
  }): Observable<ResponseSuccess> {
    return this.resendRequestToArtistOfEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }
}
