import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { debounceTime, switchMap, take } from 'rxjs/operators';

import { AdminTokenService } from '@services/admin/admin-token/admin-token.service';
import * as UserStoreSelectors from '@store/root/admin/user/user.selectors';
import { RootStoreState } from '@store/root';
import { Router } from '@angular/router';
import { getGuestTokenResponse } from '@store/root/client/user/selectors';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  tokenResponse$ = this.store.select(
    UserStoreSelectors.getUserTokenResponseState,
  );
  guestTokenResponse$ = this.store.select(getGuestTokenResponse);
  constructor(
    public afAuth: AngularFireAuth,
    private tokenService: AdminTokenService,
    private store: Store<RootStoreState.State>,
    private router: Router,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      request.url.includes('admin') &&
      !request.url.includes('admin/sessions')
    ) {
      return this.tokenResponse$.pipe(
        debounceTime(10),
        switchMap((token) => {
          if (token === null) {
            return next.handle(request);
          } else {
            if (!this.tokenService.isExpiredToken(token)) {
              const now = Date.now().valueOf() / 1000;
              const refreshTokenExp = JSON.parse(
                atob(token.refresh_token_value.split('.')[1]),
              ).exp;
              if (refreshTokenExp > now) {
                this.tokenService.refreshToken();
              } else {
                this.router.navigate(['/admin/auth']);
              }
            } else {
              return next.handle(
                request.clone({
                  setHeaders: {
                    Authorization: `Bearer ${token.token_value}`,
                  },
                }),
              );
            }
          }
        }),
      );
    }

    if (request.url.includes('google')) {
      return next.handle(request);
    }
    return this.afAuth.idToken.pipe(
      take(1),
      switchMap((token) =>
        next.handle(
          request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ),
      ),
    );
  }
}
