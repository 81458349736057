/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetNotificationsSettingsResponseSuccess } from '../models/get-notifications-settings-response-success';
import { GetTopNotificationsResponseSuccess } from '../models/get-top-notifications-response-success';
import { MarkNotificationAsViewedRequest } from '../models/mark-notification-as-viewed-request';
import { NotificationsSettings } from '../models/notifications-settings';
import { ResponseSuccess } from '../models/response-success';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation markNotificationViewed
   */
  static readonly MarkNotificationViewedPath = '/notifications/mark_as_viewed';

  /**
   * Mark notification as viewed.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markNotificationViewed()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  markNotificationViewed$Response(params: {
    /**
     * JSON
     */
    body: MarkNotificationAsViewedRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      NotificationsService.MarkNotificationViewedPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Mark notification as viewed.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `markNotificationViewed$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  markNotificationViewed(params: {
    /**
     * JSON
     */
    body: MarkNotificationAsViewedRequest;
  }): Observable<ResponseSuccess> {
    return this.markNotificationViewed$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getNotificationsTop
   */
  static readonly GetNotificationsTopPath = '/notifications/top';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNotificationsTop()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotificationsTop$Response(params?: {}): Observable<
    StrictHttpResponse<GetTopNotificationsResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      NotificationsService.GetNotificationsTopPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetTopNotificationsResponseSuccess>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNotificationsTop$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotificationsTop(params?: {}): Observable<GetTopNotificationsResponseSuccess> {
    return this.getNotificationsTop$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetTopNotificationsResponseSuccess>) =>
          r.body as GetTopNotificationsResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getUserNotificationsSettings
   */
  static readonly GetUserNotificationsSettingsPath =
    '/user/notifications_settings';

  /**
   * Get notifications settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserNotificationsSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserNotificationsSettings$Response(params?: {}): Observable<
    StrictHttpResponse<GetNotificationsSettingsResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      NotificationsService.GetUserNotificationsSettingsPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetNotificationsSettingsResponseSuccess>;
        }),
      );
  }

  /**
   * Get notifications settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserNotificationsSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserNotificationsSettings(params?: {}): Observable<GetNotificationsSettingsResponseSuccess> {
    return this.getUserNotificationsSettings$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetNotificationsSettingsResponseSuccess>) =>
          r.body as GetNotificationsSettingsResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation setUserNotificationsSettings
   */
  static readonly SetUserNotificationsSettingsPath =
    '/user/notifications_settings';

  /**
   * Set user notifications settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setUserNotificationsSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setUserNotificationsSettings$Response(params: {
    /**
     * JSON
     */
    body: NotificationsSettings;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      NotificationsService.SetUserNotificationsSettingsPath,
      'put',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Set user notifications settings.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setUserNotificationsSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setUserNotificationsSettings(params: {
    /**
     * JSON
     */
    body: NotificationsSettings;
  }): Observable<ResponseSuccess> {
    return this.setUserNotificationsSettings$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }
}
