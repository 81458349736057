/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateSupportChatMessageRequest } from '../models/create-support-chat-message-request';
import { GetChatsResponseSuccess } from '../models/get-chats-response-success';
import { GetFaqResponseSuccess } from '../models/get-faq-response-success';
import { PostFeedbackRequest } from '../models/post-feedback-request';
import { ResponseSuccess } from '../models/response-success';
import { SupportChatMessagesResponseSuccess } from '../models/support-chat-messages-response-success';

@Injectable({
  providedIn: 'root',
})
export class SupportService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation postSupportMessage
   */
  static readonly PostSupportMessagePath = '/support/chat';

  /**
   * Post message to {chatID} support chat (user side).
   *
   * If chat with {chatID} doesn't exist it will be created.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSupportMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postSupportMessage$Response(params: {
    /**
     * JSON
     */
    body: CreateSupportChatMessageRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      SupportService.PostSupportMessagePath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Post message to {chatID} support chat (user side).
   *
   * If chat with {chatID} doesn't exist it will be created.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSupportMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postSupportMessage(params: {
    /**
     * JSON
     */
    body: CreateSupportChatMessageRequest;
  }): Observable<ResponseSuccess> {
    return this.postSupportMessage$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getSupportChatMessages
   */
  static readonly GetSupportChatMessagesPath = '/support/chat/{chatID}';

  /**
   * Get support chat messages (user side).
   *
   * Please don't call this method often than 1 time per 30 seconds.
   * Please compare your received messages list for duplicates and
   * filter them by id. Add count of messages in current response
   * with count of already stored messages and look to `totalCount`
   * field and compare it. If counts are different you should refetch
   * all messages of this chat. Don't store received `totalCount`,
   * you should count all your messages manually. The reason why we
   * need to do this is race condition in DB write and read.
   * If `lastId` parameter provided then answer won't have this message.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSupportChatMessages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupportChatMessages$Response(params: {
    /**
     * Chat ID
     */
    chatID: string;

    /**
     * last known message ID
     */
    lastId?: string;
  }): Observable<StrictHttpResponse<SupportChatMessagesResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      SupportService.GetSupportChatMessagesPath,
      'get',
    );
    if (params) {
      rb.path('chatID', params.chatID, {});
      rb.query('lastId', params.lastId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SupportChatMessagesResponseSuccess>;
        }),
      );
  }

  /**
   * Get support chat messages (user side).
   *
   * Please don't call this method often than 1 time per 30 seconds.
   * Please compare your received messages list for duplicates and
   * filter them by id. Add count of messages in current response
   * with count of already stored messages and look to `totalCount`
   * field and compare it. If counts are different you should refetch
   * all messages of this chat. Don't store received `totalCount`,
   * you should count all your messages manually. The reason why we
   * need to do this is race condition in DB write and read.
   * If `lastId` parameter provided then answer won't have this message.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSupportChatMessages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupportChatMessages(params: {
    /**
     * Chat ID
     */
    chatID: string;

    /**
     * last known message ID
     */
    lastId?: string;
  }): Observable<SupportChatMessagesResponseSuccess> {
    return this.getSupportChatMessages$Response(params).pipe(
      map(
        (r: StrictHttpResponse<SupportChatMessagesResponseSuccess>) =>
          r.body as SupportChatMessagesResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getSupportChats
   */
  static readonly GetSupportChatsPath = '/support/chats';

  /**
   * Get support chats with last message (user side).
   *
   * Please don't call this method often than 1 time per 30 seconds.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSupportChats()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupportChats$Response(params?: {}): Observable<
    StrictHttpResponse<GetChatsResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      SupportService.GetSupportChatsPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetChatsResponseSuccess>;
        }),
      );
  }

  /**
   * Get support chats with last message (user side).
   *
   * Please don't call this method often than 1 time per 30 seconds.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSupportChats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupportChats(params?: {}): Observable<GetChatsResponseSuccess> {
    return this.getSupportChats$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetChatsResponseSuccess>) =>
          r.body as GetChatsResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getFaq
   */
  static readonly GetFaqPath = '/support/faq';

  /**
   * Get FAQ.
   *
   * Questions ordered by their priority.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFaq()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFaq$Response(params?: {}): Observable<
    StrictHttpResponse<GetFaqResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      SupportService.GetFaqPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetFaqResponseSuccess>;
        }),
      );
  }

  /**
   * Get FAQ.
   *
   * Questions ordered by their priority.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFaq$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFaq(params?: {}): Observable<GetFaqResponseSuccess> {
    return this.getFaq$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetFaqResponseSuccess>) =>
          r.body as GetFaqResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation postFeedback
   */
  static readonly PostFeedbackPath = '/support/feedback';

  /**
   * Posts feedback.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postFeedback()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postFeedback$Response(params: {
    /**
     * JSON
     */
    body: PostFeedbackRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      SupportService.PostFeedbackPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Posts feedback.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postFeedback$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postFeedback(params: {
    /**
     * JSON
     */
    body: PostFeedbackRequest;
  }): Observable<ResponseSuccess> {
    return this.postFeedback$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }
}
