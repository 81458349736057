import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { DatadogService } from '@services/core/monitoring/monitoring.service';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { appConfig } from '@app/app.config';

if (environment.production) {
  enableProdMode();
}

const datadogService = new DatadogService();
datadogService.init();

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
