import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { Unsubscribe } from '@interfaces/unsubscribe.interface';
import { SseService } from '@services/core/sse/sse.service';
import { SseEventHandlerServiceService } from '@services/core/sse-event-handler/sse-event-handler-service.service';
import { CartSessionStorageService } from '@services/client/session-storage/cart/cart-session-storage.service';
import { SafeAny } from '@src/types/safeAny';
import { IsPlatformService } from '@services/client/is-platform/is-platform.service';

declare let gtag: SafeAny;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit, OnDestroy, Unsubscribe {
  readonly unsubscribe = new Subject<void>();
  private eventSourceSubscription: Subscription;

  constructor(
    public router: Router,
    private cartSessionStorageService: CartSessionStorageService,
    private sseService: SseService,
    private sseEventHandlerService: SseEventHandlerServiceService,
    public isPlatformService: IsPlatformService,
  ) {
    this.router.events.subscribe((event) => {
      if (
        event instanceof NavigationEnd &&
        this.isPlatformService.isBrowser()
      ) {
        gtag('config', environment.gtagKay, {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }

  ngOnInit(): void {
    if (this.isPlatformService.isBrowser()) {
      this.eventSourceSubscription = this.sseService
        .connectToServiceSentEvents()
        .subscribe({
          next: (data) => {
            if (data) {
              this.sseEventHandlerService.handleEventData(data);
            }
          },
          error: (error) => {
            console.error(error);
          },
        });
    }
    this.cartSessionStorageService.setCartFromStorage();
    this.cartSessionStorageService.watchCartSateAndUpdateStorage();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.sseService.close();
    if (this.eventSourceSubscription) {
      this.eventSourceSubscription.unsubscribe();
    }
  }
}
