/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetArtistMediaResponse } from '../models/get-artist-media-response';
import { GetEventsResponse } from '../models/get-events-response';

@Injectable({
  providedIn: 'root',
})
export class EventsByArtistIdService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getArtisMedia
   */
  static readonly GetArtisMediaPath = '/artists/{id}/media';

  /**
   * returns all events for artist by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArtisMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtisMedia$Response(params: {
    /**
     * artist account ID
     */
    id: string;
  }): Observable<StrictHttpResponse<GetArtistMediaResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsByArtistIdService.GetArtisMediaPath,
      'get',
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetArtistMediaResponse>;
        }),
      );
  }

  /**
   * returns all events for artist by id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getArtisMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArtisMedia(params: {
    /**
     * artist account ID
     */
    id: string;
  }): Observable<GetArtistMediaResponse> {
    return this.getArtisMedia$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetArtistMediaResponse>) =>
          r.body as GetArtistMediaResponse,
      ),
    );
  }

  /**
   * Path part for operation pastEventsArtistId
   */
  static readonly PastEventsArtistIdPath = '/artists/{id}/past_events';

  /**
   * returns past events for artist by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pastEventsArtistId()` instead.
   *
   * This method doesn't expect any request body.
   */
  pastEventsArtistId$Response(params: {
    /**
     * artist account ID
     */
    id: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<StrictHttpResponse<GetEventsResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsByArtistIdService.PastEventsArtistIdPath,
      'get',
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetEventsResponse>;
        }),
      );
  }

  /**
   * returns past events for artist by id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pastEventsArtistId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pastEventsArtistId(params: {
    /**
     * artist account ID
     */
    id: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<GetEventsResponse> {
    return this.pastEventsArtistId$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetEventsResponse>) =>
          r.body as GetEventsResponse,
      ),
    );
  }

  /**
   * Path part for operation upcomingEventsArtistId
   */
  static readonly UpcomingEventsArtistIdPath = '/artists/{id}/upcoming_events';

  /**
   * returns all events for artist by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upcomingEventsArtistId()` instead.
   *
   * This method doesn't expect any request body.
   */
  upcomingEventsArtistId$Response(params: {
    /**
     * artist account ID
     */
    id: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<StrictHttpResponse<GetEventsResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsByArtistIdService.UpcomingEventsArtistIdPath,
      'get',
    );
    if (params) {
      rb.path('id', params.id, {});
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetEventsResponse>;
        }),
      );
  }

  /**
   * returns all events for artist by id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `upcomingEventsArtistId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  upcomingEventsArtistId(params: {
    /**
     * artist account ID
     */
    id: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<GetEventsResponse> {
    return this.upcomingEventsArtistId$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetEventsResponse>) =>
          r.body as GetEventsResponse,
      ),
    );
  }
}
