import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
  withEventReplay,
  withHttpTransferCacheOptions,
  withI18nSupport,
} from '@angular/platform-browser';
import { RootStoreModule } from '@store/root';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '@src/environments/environment';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { ApiModule } from '@modules/api/api.module';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { TokenInterceptor } from '@app/interceptors/token.interceptor';
import { ErrorInterceptor } from '@app/interceptors/error.interceptor';
import { appRoutes } from '@app/app.routes';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,
      RootStoreModule,
      MatFormFieldModule,
      MatDialogModule,
      MatIconModule,
      NgxMaskDirective,
      NgxMaskPipe,
      NgxStripeModule.forRoot(environment.stripePublicKey),
      AngularFireModule.initializeApp(environment.firebaseConfig),
      ApiModule.forRoot({
        rootUrl: `${environment.host}/api/v2`,
      }),
    ),
    provideRouter(appRoutes),
    provideNgxMask(),
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig },
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideAnimations(),
    provideClientHydration(
      withEventReplay(),
      withI18nSupport(),
      withHttpTransferCacheOptions({
        includePostRequests: true,
      }),
    ),
    provideAnimations(),
  ],
};
