/* tslint:disable */
/* eslint-disable */
import {
  NgModule,
  ModuleWithProviders,
  SkipSelf,
  Optional,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ApiService } from './services/api.service';
import { ArtistAccountService } from './services/artist-account.service';
import { FanAccountService } from './services/fan-account.service';
import { MessagesService } from './services/messages.service';
import { EventsByArtistIdService } from './services/events-by-artist-id.service';
import { CommentService } from './services/comment.service';
import { EventsService } from './services/events.service';
import { EventsArtistService } from './services/events-artist.service';
import { EventsFundingService } from './services/events-funding.service';
import { EventsStateService } from './services/events-state.service';
import { EventsLivestreamService } from './services/events-livestream.service';
import { TicketService } from './services/ticket.service';
import { EventsVenueService } from './services/events-venue.service';
import { EventsByFanIdService } from './services/events-by-fan-id.service';
import { VenueAccountService } from './services/venue-account.service';
import { GenreService } from './services/genre.service';
import { NotificationsService } from './services/notifications.service';
import { PaymentService } from './services/payment.service';
import { UserService } from './services/user.service';
import { SearchService } from './services/search.service';
import { SearchEventService } from './services/search-event.service';
import { SupportService } from './services/support.service';
import { PaymentMethodService } from './services/payment-method.service';
import { UserProfileService } from './services/user-profile.service';
import { VenuePhotoService } from './services/venue-photo.service';
import { VideoService } from './services/video.service';
import { OrganizerAccountService } from '@modules/api/services';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApiService,
    ArtistAccountService,
    FanAccountService,
    MessagesService,
    EventsByArtistIdService,
    CommentService,
    EventsService,
    EventsArtistService,
    EventsFundingService,
    EventsStateService,
    EventsLivestreamService,
    TicketService,
    EventsVenueService,
    OrganizerAccountService,
    EventsByFanIdService,
    VenueAccountService,
    GenreService,
    NotificationsService,
    PaymentService,
    UserService,
    SearchService,
    SearchEventService,
    SupportService,
    PaymentMethodService,
    UserProfileService,
    VenuePhotoService,
    VideoService,
    ApiConfiguration,
  ],
})
export class ApiModule {
  static forRoot(
    params: ApiConfigurationParams,
  ): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params,
        },
      ],
    };
  }

  constructor(
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient,
  ) {
    if (parentModule) {
      throw new Error(
        'ApiModule is already loaded. Import in your base AppModule only.',
      );
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575',
      );
    }
  }
}
