/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateOrUpdateFundingRequest } from '../models/create-or-update-funding-request';
import { CreatePaymentResponse } from '../models/create-payment-response';
import { EventFundingResponse } from '../models/event-funding-response';
import { PayForArtistOfEventRequest } from '../models/pay-for-artist-of-event-request';
import { PayForVenueOfEventRequest } from '../models/pay-for-venue-of-event-request';
import { ResponseSuccess } from '../models/response-success';

@Injectable({
  providedIn: 'root',
})
export class EventsFundingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation payForArtistOfEvent
   */
  static readonly PayForArtistOfEventPath =
    '/events/{eventGUID}/artists/{artistGUID}/pay';

  /**
   * Pay for artist of event.
   *
   * Pay for artist of event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `payForArtistOfEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  payForArtistOfEvent$Response(params: {
    /**
     * Event ID
     */
    eventGUID: string;

    /**
     * Artist ID (Example: ID of artist profile)
     */
    artistGUID: string;

    /**
     * PayForArtistOfEventRequest
     */
    body: PayForArtistOfEventRequest;
  }): Observable<StrictHttpResponse<CreatePaymentResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsFundingService.PayForArtistOfEventPath,
      'post',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.path('artistGUID', params.artistGUID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreatePaymentResponse>;
        }),
      );
  }

  /**
   * Pay for artist of event.
   *
   * Pay for artist of event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `payForArtistOfEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  payForArtistOfEvent(params: {
    /**
     * Event ID
     */
    eventGUID: string;

    /**
     * Artist ID (Example: ID of artist profile)
     */
    artistGUID: string;

    /**
     * PayForArtistOfEventRequest
     */
    body: PayForArtistOfEventRequest;
  }): Observable<CreatePaymentResponse> {
    return this.payForArtistOfEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<CreatePaymentResponse>) =>
          r.body as CreatePaymentResponse,
      ),
    );
  }

  /**
   * Path part for operation getFundingDetailsOfEvent
   */
  static readonly GetFundingDetailsOfEventPath = '/events/{eventGUID}/funding';

  /**
   * Get funding details of event.
   *
   * Get funding details of event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFundingDetailsOfEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFundingDetailsOfEvent$Response(params: {
    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<StrictHttpResponse<EventFundingResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsFundingService.GetFundingDetailsOfEventPath,
      'get',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EventFundingResponse>;
        }),
      );
  }

  /**
   * Get funding details of event.
   *
   * Get funding details of event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFundingDetailsOfEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFundingDetailsOfEvent(params: {
    /**
     * Event ID
     */
    eventGUID: string;
  }): Observable<EventFundingResponse> {
    return this.getFundingDetailsOfEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<EventFundingResponse>) =>
          r.body as EventFundingResponse,
      ),
    );
  }

  /**
   * Path part for operation createOrUpdateFundingForEvent
   */
  static readonly CreateOrUpdateFundingForEventPath =
    '/events/{eventGUID}/funding';

  /**
   * Create / update funding part of event.
   *
   * Create / update funding part of event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateFundingForEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrUpdateFundingForEvent$Response(params: {
    /**
     * Event ID
     */
    eventGUID: string;

    /**
     * CreateOrUpdateFundingRequest
     */
    body: CreateOrUpdateFundingRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsFundingService.CreateOrUpdateFundingForEventPath,
      'put',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Create / update funding part of event.
   *
   * Create / update funding part of event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateFundingForEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrUpdateFundingForEvent(params: {
    /**
     * Event ID
     */
    eventGUID: string;

    /**
     * CreateOrUpdateFundingRequest
     */
    body: CreateOrUpdateFundingRequest;
  }): Observable<ResponseSuccess> {
    return this.createOrUpdateFundingForEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation payForVenueOfEvent
   */
  static readonly PayForVenueOfEventPath =
    '/events/{eventGUID}/venues/{venueGUID}/pay';

  /**
   * Pay for venue of event.
   *
   * Pay for venue of event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `payForVenueOfEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  payForVenueOfEvent$Response(params: {
    /**
     * Event ID
     */
    eventGUID: string;

    /**
     * Venue ID (Example: ID of venue profile)
     */
    venueGUID: string;

    /**
     * PayForVenueOfEventRequest
     */
    body: PayForVenueOfEventRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      EventsFundingService.PayForVenueOfEventPath,
      'post',
    );
    if (params) {
      rb.path('eventGUID', params.eventGUID, {});
      rb.path('venueGUID', params.venueGUID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Pay for venue of event.
   *
   * Pay for venue of event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `payForVenueOfEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  payForVenueOfEvent(params: {
    /**
     * Event ID
     */
    eventGUID: string;

    /**
     * Venue ID (Example: ID of venue profile)
     */
    venueGUID: string;

    /**
     * PayForVenueOfEventRequest
     */
    body: PayForVenueOfEventRequest;
  }): Observable<ResponseSuccess> {
    return this.payForVenueOfEvent$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }
}
