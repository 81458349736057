/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserFollowByAccountTypesResponse } from '../models/user-follow-by-account-types-response';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getUserProfileFollowers
   */
  static readonly GetUserProfileFollowersPath =
    '/user/profiles/{profileID}/followers';

  /**
   * Retrieves list of followers for profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserProfileFollowers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProfileFollowers$Response(params: {
    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * Profile ID (Example: ID of fan account)
     */
    profileID: string;

    /**
     * Account Type (Example: artist_account / organizer_account / venue_account / fan_account)
     */
    accountType: string;
  }): Observable<StrictHttpResponse<UserFollowByAccountTypesResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserProfileService.GetUserProfileFollowersPath,
      'get',
    );
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
      rb.path('profileID', params.profileID, {});
      rb.query('accountType', params.accountType, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserFollowByAccountTypesResponse>;
        }),
      );
  }

  /**
   * Retrieves list of followers for profile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserProfileFollowers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProfileFollowers(params: {
    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * Profile ID (Example: ID of fan account)
     */
    profileID: string;

    /**
     * Account Type (Example: artist_account / organizer_account / venue_account / fan_account)
     */
    accountType: string;
  }): Observable<UserFollowByAccountTypesResponse> {
    return this.getUserProfileFollowers$Response(params).pipe(
      map(
        (r: StrictHttpResponse<UserFollowByAccountTypesResponse>) =>
          r.body as UserFollowByAccountTypesResponse,
      ),
    );
  }

  /**
   * Path part for operation getUserProfileFollowing
   */
  static readonly GetUserProfileFollowingPath =
    '/user/profiles/{profileID}/following';

  /**
   * Retrieves list of following for profile.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserProfileFollowing()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProfileFollowing$Response(params: {
    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * Profile ID (Example: ID of fan account)
     */
    profileID: string;

    /**
     * Account Type (Example: artist_account / organizer_account / venue_account / fan_account)
     */
    accountType: string;
  }): Observable<StrictHttpResponse<UserFollowByAccountTypesResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      UserProfileService.GetUserProfileFollowingPath,
      'get',
    );
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
      rb.path('profileID', params.profileID, {});
      rb.query('accountType', params.accountType, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserFollowByAccountTypesResponse>;
        }),
      );
  }

  /**
   * Retrieves list of following for profile.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserProfileFollowing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProfileFollowing(params: {
    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;

    /**
     * Profile ID (Example: ID of fan account)
     */
    profileID: string;

    /**
     * Account Type (Example: artist_account / organizer_account / venue_account / fan_account)
     */
    accountType: string;
  }): Observable<UserFollowByAccountTypesResponse> {
    return this.getUserProfileFollowing$Response(params).pipe(
      map(
        (r: StrictHttpResponse<UserFollowByAccountTypesResponse>) =>
          r.body as UserFollowByAccountTypesResponse,
      ),
    );
  }
}
