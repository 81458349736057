import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackBarService } from '@services/client/snack-bar.service';
import { SafeAny } from '@src/types/safeAny';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private snackBarService: SnackBarService) {}

  intercept(
    request: HttpRequest<SafeAny>,
    next: HttpHandler,
  ): Observable<HttpEvent<SafeAny>> {
    const token = request.headers.get('Authorization');
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const ignoredUrls = '/qrcode_scanner';
        const ignoredError = 'CustomURL';
        const isIgnoredUrl = error.url?.includes(ignoredUrls);
        if ([400].includes(error?.status) && isIgnoredUrl) {
          return throwError(() => error);
        }
        if (![401, 403, 404, 409].includes(error?.status)) {
          const errorWithToken = {
            ...error,
            method: request.method,
            payload: request.body || 'No payload',
            token: token || 'No token found',
          };
          console.error('HTTP Error with Token:', errorWithToken);
        }
        if (!error.error.error.includes(ignoredError)) {
          this.snackBarService.setError(error.error.error);
          setTimeout(() => {
            this.snackBarService.setError('');
          }, 5000);
        }
        return throwError(error);
      }),
    );
  }
}
