/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateCalendarItemRequest } from '../models/create-calendar-item-request';
import { CreateVenueAccountCalendarItemResponseSuccess } from '../models/create-venue-account-calendar-item-response-success';
import { CreateVenueAccountRequest } from '../models/create-venue-account-request';
import { DeleteVenueAccountCalendarItemResponseSuccess } from '../models/delete-venue-account-calendar-item-response-success';
import { FollowersCount } from '../models/followers-count';
import { GetEventsResponse } from '../models/get-events-response';
import { GetVenueAccountCalendarResponseSuccess } from '../models/get-venue-account-calendar-response-success';
import { GetVenueAccountInfoResponseSuccess } from '../models/get-venue-account-info-response-success';
import { GetVenueMediaResponse } from '../models/get-venue-media-response';
import { JoinToStripeRequest } from '../models/join-to-stripe-request';
import { ResponseSuccess } from '../models/response-success';
import { UpdateVenueAccountDatesSettingsRequest } from '../models/update-venue-account-dates-settings-request';
import { UpdateVenueAccountListingDetailsRequest } from '../models/update-venue-account-listing-details-request';
import { UpdateVenueAccountMediaRequest } from '../models/update-venue-account-media-request';
import { UpdateVenueAccountRequest } from '../models/update-venue-account-request';
import { VenueAccountStatusResponse } from '../models/venue-account-status-response';
import { VenueProfileResponse } from '../models/venue-profile-response';

@Injectable({
  providedIn: 'root',
})
export class VenueAccountService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getVenueFollowersCount
   */
  static readonly GetVenueFollowersCountPath =
    '/fans/{userGUID}/venue/followers_count';

  /**
   * Followers count.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVenueFollowersCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenueFollowersCount$Response(params: {
    /**
     * Account ID
     */
    userGUID: string;
  }): Observable<StrictHttpResponse<FollowersCount>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenueAccountService.GetVenueFollowersCountPath,
      'get',
    );
    if (params) {
      rb.path('userGUID', params.userGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FollowersCount>;
        }),
      );
  }

  /**
   * Followers count.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVenueFollowersCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenueFollowersCount(params: {
    /**
     * Account ID
     */
    userGUID: string;
  }): Observable<FollowersCount> {
    return this.getVenueFollowersCount$Response(params).pipe(
      map((r: StrictHttpResponse<FollowersCount>) => r.body as FollowersCount),
    );
  }

  /**
   * Path part for operation createVenueAccount
   */
  static readonly CreateVenueAccountPath = '/venue';

  /**
   * Create venue account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createVenueAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createVenueAccount$Response(params: {
    /**
     * Get
     */
    body: CreateVenueAccountRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenueAccountService.CreateVenueAccountPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Create venue account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createVenueAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createVenueAccount(params: {
    /**
     * Get
     */
    body: CreateVenueAccountRequest;
  }): Observable<ResponseSuccess> {
    return this.createVenueAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation removeVenueAccount
   */
  static readonly RemoveVenueAccountPath = '/venue';

  /**
   * Remove venue account.
   *
   * Remove venue account from user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeVenueAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeVenueAccount$Response(params?: {}): Observable<
    StrictHttpResponse<ResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenueAccountService.RemoveVenueAccountPath,
      'delete',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Remove venue account.
   *
   * Remove venue account from user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeVenueAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeVenueAccount(params?: {}): Observable<ResponseSuccess> {
    return this.removeVenueAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation updateVenueAccount
   */
  static readonly UpdateVenueAccountPath = '/venue/about';

  /**
   * Update venue account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateVenueAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVenueAccount$Response(params: {
    /**
     * Get
     */
    body: UpdateVenueAccountRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenueAccountService.UpdateVenueAccountPath,
      'put',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Update venue account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateVenueAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVenueAccount(params: {
    /**
     * Get
     */
    body: UpdateVenueAccountRequest;
  }): Observable<ResponseSuccess> {
    return this.updateVenueAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation createCalendarItem
   */
  static readonly CreateCalendarItemPath = '/venue/dates';

  /**
   * Add calendar item.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCalendarItem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCalendarItem$Response(params: {
    /**
     * Post
     */
    body: CreateCalendarItemRequest;
  }): Observable<
    StrictHttpResponse<CreateVenueAccountCalendarItemResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenueAccountService.CreateCalendarItemPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CreateVenueAccountCalendarItemResponseSuccess>;
        }),
      );
  }

  /**
   * Add calendar item.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createCalendarItem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCalendarItem(params: {
    /**
     * Post
     */
    body: CreateCalendarItemRequest;
  }): Observable<CreateVenueAccountCalendarItemResponseSuccess> {
    return this.createCalendarItem$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<CreateVenueAccountCalendarItemResponseSuccess>,
        ) => r.body as CreateVenueAccountCalendarItemResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation deleteCalendarItem
   */
  static readonly DeleteCalendarItemPath = '/venue/dates/{id}';

  /**
   * Remove calendar item.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCalendarItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCalendarItem$Response(params: {
    /**
     * Venue Dates ID
     */
    id: string;
  }): Observable<
    StrictHttpResponse<DeleteVenueAccountCalendarItemResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenueAccountService.DeleteCalendarItemPath,
      'delete',
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<DeleteVenueAccountCalendarItemResponseSuccess>;
        }),
      );
  }

  /**
   * Remove calendar item.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCalendarItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCalendarItem(params: {
    /**
     * Venue Dates ID
     */
    id: string;
  }): Observable<DeleteVenueAccountCalendarItemResponseSuccess> {
    return this.deleteCalendarItem$Response(params).pipe(
      map(
        (
          r: StrictHttpResponse<DeleteVenueAccountCalendarItemResponseSuccess>,
        ) => r.body as DeleteVenueAccountCalendarItemResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation updateVenueAccountDates
   */
  static readonly UpdateVenueAccountDatesPath = '/venue/dates_settings';

  /**
   * Update venue account dates.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateVenueAccountDates()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVenueAccountDates$Response(params: {
    /**
     * Get
     */
    body: UpdateVenueAccountDatesSettingsRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenueAccountService.UpdateVenueAccountDatesPath,
      'put',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Update venue account dates.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateVenueAccountDates$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVenueAccountDates(params: {
    /**
     * Get
     */
    body: UpdateVenueAccountDatesSettingsRequest;
  }): Observable<ResponseSuccess> {
    return this.updateVenueAccountDates$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation updateVenueAccountListingDetails
   */
  static readonly UpdateVenueAccountListingDetailsPath =
    '/venue/listing_details';

  /**
   * Update venue account listing details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateVenueAccountListingDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVenueAccountListingDetails$Response(params: {
    /**
     * Get
     */
    body: UpdateVenueAccountListingDetailsRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenueAccountService.UpdateVenueAccountListingDetailsPath,
      'put',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Update venue account listing details.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateVenueAccountListingDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVenueAccountListingDetails(params: {
    /**
     * Get
     */
    body: UpdateVenueAccountListingDetailsRequest;
  }): Observable<ResponseSuccess> {
    return this.updateVenueAccountListingDetails$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation updateVenueAccountMedia
   */
  static readonly UpdateVenueAccountMediaPath = '/venue/media';

  /**
   * Update venue account media.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateVenueAccountMedia()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVenueAccountMedia$Response(params: {
    /**
     * Get
     */
    body: UpdateVenueAccountMediaRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenueAccountService.UpdateVenueAccountMediaPath,
      'put',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Update venue account media.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateVenueAccountMedia$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateVenueAccountMedia(params: {
    /**
     * Get
     */
    body: UpdateVenueAccountMediaRequest;
  }): Observable<ResponseSuccess> {
    return this.updateVenueAccountMedia$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getVenueAccount
   */
  static readonly GetVenueAccountPath = '/venue/profile';

  /**
   * returns all info that can be exposed to client.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVenueAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenueAccount$Response(params?: {}): Observable<
    StrictHttpResponse<GetVenueAccountInfoResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenueAccountService.GetVenueAccountPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetVenueAccountInfoResponseSuccess>;
        }),
      );
  }

  /**
   * returns all info that can be exposed to client.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVenueAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenueAccount(params?: {}): Observable<GetVenueAccountInfoResponseSuccess> {
    return this.getVenueAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetVenueAccountInfoResponseSuccess>) =>
          r.body as GetVenueAccountInfoResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getVenue
   */
  static readonly GetVenuePath = '/venue/profile/{id}';

  /**
   * returns all info that can be exposed to client for this venue.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVenue()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenue$Response(params: {
    /**
     * Venue Profile ID
     */
    id: string;
  }): Observable<StrictHttpResponse<VenueProfileResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenueAccountService.GetVenuePath,
      'get',
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VenueProfileResponse>;
        }),
      );
  }

  /**
   * returns all info that can be exposed to client for this venue.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVenue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenue(params: {
    /**
     * Venue Profile ID
     */
    id: string;
  }): Observable<VenueProfileResponse> {
    return this.getVenue$Response(params).pipe(
      map(
        (r: StrictHttpResponse<VenueProfileResponse>) =>
          r.body as VenueProfileResponse,
      ),
    );
  }

  /**
   * Path part for operation uploadProfilePhotoVenueAccount
   */
  static readonly UploadProfilePhotoVenueAccountPath = '/venue/profile_photo';

  /**
   * Upload profile photo.
   *
   * Upload profile photo to venue account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadProfilePhotoVenueAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadProfilePhotoVenueAccount$Response(params: {
    /**
     * multipart/form-data image&#x3D;file
     */
    query: string;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenueAccountService.UploadProfilePhotoVenueAccountPath,
      'post',
    );
    if (params) {
      rb.query('query', params.query, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Upload profile photo.
   *
   * Upload profile photo to venue account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadProfilePhotoVenueAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadProfilePhotoVenueAccount(params: {
    /**
     * multipart/form-data image&#x3D;file
     */
    query: string;
  }): Observable<ResponseSuccess> {
    return this.uploadProfilePhotoVenueAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation removeProfilePhotoVenueAccount
   */
  static readonly RemoveProfilePhotoVenueAccountPath = '/venue/profile_photo';

  /**
   * Remove profile photo.
   *
   * Remove profile photo from venue account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeProfilePhotoVenueAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeProfilePhotoVenueAccount$Response(params?: {}): Observable<
    StrictHttpResponse<ResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenueAccountService.RemoveProfilePhotoVenueAccountPath,
      'delete',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Remove profile photo.
   *
   * Remove profile photo from venue account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeProfilePhotoVenueAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeProfilePhotoVenueAccount(params?: {}): Observable<ResponseSuccess> {
    return this.removeProfilePhotoVenueAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation reviewVenueAccount
   */
  static readonly ReviewVenueAccountPath = '/venue/review';

  /**
   * Sends venue account for review and return current status.
   *
   * Review venue account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reviewVenueAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  reviewVenueAccount$Response(params?: {}): Observable<
    StrictHttpResponse<VenueAccountStatusResponse>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenueAccountService.ReviewVenueAccountPath,
      'put',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VenueAccountStatusResponse>;
        }),
      );
  }

  /**
   * Sends venue account for review and return current status.
   *
   * Review venue account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reviewVenueAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reviewVenueAccount(params?: {}): Observable<VenueAccountStatusResponse> {
    return this.reviewVenueAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<VenueAccountStatusResponse>) =>
          r.body as VenueAccountStatusResponse,
      ),
    );
  }

  /**
   * Path part for operation statusVenueAccountStatus
   */
  static readonly StatusVenueAccountStatusPath = '/venue/status';

  /**
   * returns venue account status.
   *
   * Venue account status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statusVenueAccountStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusVenueAccountStatus$Response(params?: {}): Observable<
    StrictHttpResponse<ResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenueAccountService.StatusVenueAccountStatusPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * returns venue account status.
   *
   * Venue account status
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `statusVenueAccountStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusVenueAccountStatus(params?: {}): Observable<ResponseSuccess> {
    return this.statusVenueAccountStatus$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation venueJoinToStripe
   */
  static readonly VenueJoinToStripePath = '/venue/stripe';

  /**
   * Join to stripe.
   *
   * how to create code look this https://stripe.com/docs/connect/standard-accounts
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `venueJoinToStripe()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  venueJoinToStripe$Response(params: {
    /**
     * Get
     */
    body: JoinToStripeRequest;
  }): Observable<StrictHttpResponse<ResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenueAccountService.VenueJoinToStripePath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResponseSuccess>;
        }),
      );
  }

  /**
   * Join to stripe.
   *
   * how to create code look this https://stripe.com/docs/connect/standard-accounts
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `venueJoinToStripe$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  venueJoinToStripe(params: {
    /**
     * Get
     */
    body: JoinToStripeRequest;
  }): Observable<ResponseSuccess> {
    return this.venueJoinToStripe$Response(params).pipe(
      map(
        (r: StrictHttpResponse<ResponseSuccess>) => r.body as ResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation mediaVenueId
   */
  static readonly MediaVenueIdPath = '/venues/{id}/media';

  /**
   * returns all media for venue by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediaVenueId()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaVenueId$Response(params: {
    /**
     * Venue ID
     */
    id: string;
  }): Observable<StrictHttpResponse<GetVenueMediaResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenueAccountService.MediaVenueIdPath,
      'get',
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetVenueMediaResponse>;
        }),
      );
  }

  /**
   * returns all media for venue by id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mediaVenueId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaVenueId(params: {
    /**
     * Venue ID
     */
    id: string;
  }): Observable<GetVenueMediaResponse> {
    return this.mediaVenueId$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetVenueMediaResponse>) =>
          r.body as GetVenueMediaResponse,
      ),
    );
  }

  /**
   * Path part for operation pastEventsVenueAccount
   */
  static readonly PastEventsVenueAccountPath = '/venues/{id}/past_events';

  /**
   * Get Past events for venue by id.
   *
   * Get Past events for venue by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pastEventsVenueAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  pastEventsVenueAccount$Response(params: {
    /**
     * Venue ID
     */
    id: string;
  }): Observable<StrictHttpResponse<GetEventsResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenueAccountService.PastEventsVenueAccountPath,
      'get',
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetEventsResponse>;
        }),
      );
  }

  /**
   * Get Past events for venue by id.
   *
   * Get Past events for venue by id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `pastEventsVenueAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pastEventsVenueAccount(params: {
    /**
     * Venue ID
     */
    id: string;
  }): Observable<GetEventsResponse> {
    return this.pastEventsVenueAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetEventsResponse>) =>
          r.body as GetEventsResponse,
      ),
    );
  }

  /**
   * Path part for operation statusVenueAccountStatusId
   */
  static readonly StatusVenueAccountStatusIdPath = '/venues/{id}/status';

  /**
   * returns venue account status.
   *
   * Venue account status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statusVenueAccountStatusId()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusVenueAccountStatusId$Response(params: {
    /**
     * Venue ID
     */
    id: string;
  }): Observable<StrictHttpResponse<GetVenueMediaResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenueAccountService.StatusVenueAccountStatusIdPath,
      'get',
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetVenueMediaResponse>;
        }),
      );
  }

  /**
   * returns venue account status.
   *
   * Venue account status
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `statusVenueAccountStatusId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusVenueAccountStatusId(params: {
    /**
     * Venue ID
     */
    id: string;
  }): Observable<GetVenueMediaResponse> {
    return this.statusVenueAccountStatusId$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetVenueMediaResponse>) =>
          r.body as GetVenueMediaResponse,
      ),
    );
  }

  /**
   * Path part for operation upcomingEventsVenueAccount
   */
  static readonly UpcomingEventsVenueAccountPath =
    '/venues/{id}/upcoming_events';

  /**
   * Get Upcoming events for venue by id.
   *
   * Get Upcoming events for venue by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upcomingEventsVenueAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  upcomingEventsVenueAccount$Response(params: {
    /**
     * Venue ID
     */
    id: string;
  }): Observable<StrictHttpResponse<GetEventsResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenueAccountService.UpcomingEventsVenueAccountPath,
      'get',
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetEventsResponse>;
        }),
      );
  }

  /**
   * Get Upcoming events for venue by id.
   *
   * Get Upcoming events for venue by id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `upcomingEventsVenueAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  upcomingEventsVenueAccount(params: {
    /**
     * Venue ID
     */
    id: string;
  }): Observable<GetEventsResponse> {
    return this.upcomingEventsVenueAccount$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetEventsResponse>) =>
          r.body as GetEventsResponse,
      ),
    );
  }

  /**
   * Path part for operation getVenueAccountCalendar
   */
  static readonly GetVenueAccountCalendarPath =
    '/venues/{venueAccountGUID}/calendar';

  /**
   * Retrieves venue dates.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVenueAccountCalendar()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenueAccountCalendar$Response(params: {
    /**
     * filter[from]
     */
    from?: string;

    /**
     * filter[to]
     */
    to?: string;

    /**
     * artist account ID
     */
    venueAccountGUID: string;
  }): Observable<StrictHttpResponse<GetVenueAccountCalendarResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      VenueAccountService.GetVenueAccountCalendarPath,
      'get',
    );
    if (params) {
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
      rb.path('venueAccountGUID', params.venueAccountGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetVenueAccountCalendarResponseSuccess>;
        }),
      );
  }

  /**
   * Retrieves venue dates.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVenueAccountCalendar$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenueAccountCalendar(params: {
    /**
     * filter[from]
     */
    from?: string;

    /**
     * filter[to]
     */
    to?: string;

    /**
     * artist account ID
     */
    venueAccountGUID: string;
  }): Observable<GetVenueAccountCalendarResponseSuccess> {
    return this.getVenueAccountCalendar$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetVenueAccountCalendarResponseSuccess>) =>
          r.body as GetVenueAccountCalendarResponseSuccess,
      ),
    );
  }
}
