/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FanAccountsResponseSuccess } from '../models/fan-accounts-response-success';
import { GetArtistAccountsBookingResponseSuccess } from '../models/get-artist-accounts-booking-response-success';
import { GetEventsResponseSuccess } from '../models/get-events-response-success';
import { GetSuggestionsResponseSuccess } from '../models/get-suggestions-response-success';
import { OrganizerAccountsResponseSuccess } from '../models/organizer-accounts-response-success';
import { SearchResponseSuccess } from '../models/search-response-success';
import { TagsResponseSuccess } from '../models/tags-response-success';
import { VenueAccountsResponseSuccess } from '../models/venue-accounts-response-success';

@Injectable({
  providedIn: 'root',
})
export class SearchService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation globalSearch
   */
  static readonly GlobalSearchPath = '/search';

  /**
   * Information about events, artist accounts, fan accounts, organizer accounts, venue accounts.
   *
   * returns list of events, artist accounts, fan accounts, organizer accounts, venue accounts
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `globalSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  globalSearch$Response(params?: {
    /**
     * search string
     */
    query?: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<StrictHttpResponse<SearchResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      SearchService.GlobalSearchPath,
      'get',
    );
    if (params) {
      rb.query('query', params.query, {});
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SearchResponseSuccess>;
        }),
      );
  }

  /**
   * Information about events, artist accounts, fan accounts, organizer accounts, venue accounts.
   *
   * returns list of events, artist accounts, fan accounts, organizer accounts, venue accounts
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `globalSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  globalSearch(params?: {
    /**
     * search string
     */
    query?: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<SearchResponseSuccess> {
    return this.globalSearch$Response(params).pipe(
      map(
        (r: StrictHttpResponse<SearchResponseSuccess>) =>
          r.body as SearchResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation searchArtistAccounts
   */
  static readonly SearchArtistAccountsPath = '/search/artist';

  /**
   * Information about artist accounts.
   *
   * returns list of  artist accounts
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchArtistAccounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchArtistAccounts$Response(params?: {
    /**
     * search string
     */
    query?: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<StrictHttpResponse<GetArtistAccountsBookingResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      SearchService.SearchArtistAccountsPath,
      'get',
    );
    if (params) {
      rb.query('query', params.query, {});
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetArtistAccountsBookingResponseSuccess>;
        }),
      );
  }

  /**
   * Information about artist accounts.
   *
   * returns list of  artist accounts
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchArtistAccounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchArtistAccounts(params?: {
    /**
     * search string
     */
    query?: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<GetArtistAccountsBookingResponseSuccess> {
    return this.searchArtistAccounts$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetArtistAccountsBookingResponseSuccess>) =>
          r.body as GetArtistAccountsBookingResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation eventSearch
   */
  static readonly EventSearchPath = '/search/event';

  /**
   * Information about events.
   *
   * returns list of  event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventSearch$Response(params?: {
    /**
     * search string
     */
    query?: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<StrictHttpResponse<GetEventsResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      SearchService.EventSearchPath,
      'get',
    );
    if (params) {
      rb.query('query', params.query, {});
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetEventsResponseSuccess>;
        }),
      );
  }

  /**
   * Information about events.
   *
   * returns list of  event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `eventSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventSearch(params?: {
    /**
     * search string
     */
    query?: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<GetEventsResponseSuccess> {
    return this.eventSearch$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetEventsResponseSuccess>) =>
          r.body as GetEventsResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation fanSearch
   */
  static readonly FanSearchPath = '/search/fan';

  /**
   * Information about fan accounts.
   *
   * returns list of  fan accounts
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fanSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  fanSearch$Response(params?: {
    /**
     * search string
     */
    query?: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<StrictHttpResponse<FanAccountsResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      SearchService.FanSearchPath,
      'get',
    );
    if (params) {
      rb.query('query', params.query, {});
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FanAccountsResponseSuccess>;
        }),
      );
  }

  /**
   * Information about fan accounts.
   *
   * returns list of  fan accounts
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fanSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fanSearch(params?: {
    /**
     * search string
     */
    query?: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<FanAccountsResponseSuccess> {
    return this.fanSearch$Response(params).pipe(
      map(
        (r: StrictHttpResponse<FanAccountsResponseSuccess>) =>
          r.body as FanAccountsResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation organizerSearch
   */
  static readonly OrganizerSearchPath = '/search/organizer';

  /**
   * Information about Organizer Accounts.
   *
   * returns list of Organizer Accounts
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizerSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizerSearch$Response(params?: {
    /**
     * search string
     */
    query?: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<StrictHttpResponse<OrganizerAccountsResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      SearchService.OrganizerSearchPath,
      'get',
    );
    if (params) {
      rb.query('query', params.query, {});
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<OrganizerAccountsResponseSuccess>;
        }),
      );
  }

  /**
   * Information about Organizer Accounts.
   *
   * returns list of Organizer Accounts
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `organizerSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizerSearch(params?: {
    /**
     * search string
     */
    query?: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<OrganizerAccountsResponseSuccess> {
    return this.organizerSearch$Response(params).pipe(
      map(
        (r: StrictHttpResponse<OrganizerAccountsResponseSuccess>) =>
          r.body as OrganizerAccountsResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation suggestions
   */
  static readonly SuggestionsPath = '/search/suggest';

  /**
   * Autocomplete search api.
   *
   * returns list of suggestions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `suggestions()` instead.
   *
   * This method doesn't expect any request body.
   */
  suggestions$Response(params?: {
    /**
     * search string
     */
    query?: string;
  }): Observable<StrictHttpResponse<GetSuggestionsResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      SearchService.SuggestionsPath,
      'get',
    );
    if (params) {
      rb.query('query', params.query, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetSuggestionsResponseSuccess>;
        }),
      );
  }

  /**
   * Autocomplete search api.
   *
   * returns list of suggestions
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `suggestions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  suggestions(params?: {
    /**
     * search string
     */
    query?: string;
  }): Observable<GetSuggestionsResponseSuccess> {
    return this.suggestions$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetSuggestionsResponseSuccess>) =>
          r.body as GetSuggestionsResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getTags
   */
  static readonly GetTagsPath = '/search/tags';

  /**
   * Information about tags.
   *
   * returns list of tags
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTags$Response(params?: {
    /**
     * search string
     */
    query?: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<StrictHttpResponse<TagsResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      SearchService.GetTagsPath,
      'get',
    );
    if (params) {
      rb.query('query', params.query, {});
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TagsResponseSuccess>;
        }),
      );
  }

  /**
   * Information about tags.
   *
   * returns list of tags
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTags(params?: {
    /**
     * search string
     */
    query?: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<TagsResponseSuccess> {
    return this.getTags$Response(params).pipe(
      map(
        (r: StrictHttpResponse<TagsResponseSuccess>) =>
          r.body as TagsResponseSuccess,
      ),
    );
  }

  /**
   * Path part for operation getVenueAccounts
   */
  static readonly GetVenueAccountsPath = '/search/venue';

  /**
   * Information about venue accounts.
   *
   * returns list of venue accounts
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVenueAccounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenueAccounts$Response(params?: {
    /**
     * search string
     */
    query?: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<StrictHttpResponse<VenueAccountsResponseSuccess>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      SearchService.GetVenueAccountsPath,
      'get',
    );
    if (params) {
      rb.query('query', params.query, {});
      rb.query('limit', params.limit, {});
      rb.query('page', params.page, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VenueAccountsResponseSuccess>;
        }),
      );
  }

  /**
   * Information about venue accounts.
   *
   * returns list of venue accounts
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVenueAccounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVenueAccounts(params?: {
    /**
     * search string
     */
    query?: string;

    /**
     * limit
     */
    limit?: number;

    /**
     * page
     */
    page?: number;
  }): Observable<VenueAccountsResponseSuccess> {
    return this.getVenueAccounts$Response(params).pipe(
      map(
        (r: StrictHttpResponse<VenueAccountsResponseSuccess>) =>
          r.body as VenueAccountsResponseSuccess,
      ),
    );
  }
}
