/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetGenresResponseSuccess } from '../models/get-genres-response-success';

@Injectable({
  providedIn: 'root',
})
export class GenreService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getGenreList
   */
  static readonly GetGenreListPath = '/genres';

  /**
   * Get genre list.
   *
   * returns list of genres
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGenreList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGenreList$Response(params?: {}): Observable<
    StrictHttpResponse<GetGenresResponseSuccess>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      GenreService.GetGenreListPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<GetGenresResponseSuccess>;
        }),
      );
  }

  /**
   * Get genre list.
   *
   * returns list of genres
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGenreList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGenreList(params?: {}): Observable<GetGenresResponseSuccess> {
    return this.getGenreList$Response(params).pipe(
      map(
        (r: StrictHttpResponse<GetGenresResponseSuccess>) =>
          r.body as GetGenresResponseSuccess,
      ),
    );
  }
}
